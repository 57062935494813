import type React from "react";
import { useRef, useState } from "react";
import { useRootDispatch } from "~redux/hooks";
import type { EdstView } from "types/edstView";
import { setIsFullscreen, setViewDimension, setViewPosition } from "~redux/slices/edstSlice";
import type { Container, FederatedPointerEvent } from "pixi.js";
import { setSelectedViewOption, TBP, usePixiMouseEventListener, useStableCallback } from "@poscon/shared-frontend";
import { useDragContext } from "contexts/dragContext";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import type { CompassDirection } from "@poscon/shared-types";

export const resizeCursorCenterMap: Record<CompassDirection, [number, number]> = {
  E: [21, 12],
  N: [12, 2],
  NE: [18, 1],
  NW: [2, 2],
  S: [12, 21],
  SE: [18, 18],
  SW: [2, 18],
  W: [2, 12],
};

export type AnchorDirection = -1 | 0 | 1;

export function getCursorDirectionFromAnchor(anchorX: AnchorDirection, anchorY: AnchorDirection) {
  const ew = anchorX === 1 ? "E" : anchorX === -1 ? "W" : "";
  const ns = anchorY === 1 ? "S" : anchorY === -1 ? "N" : "";
  return (ns + ew) as CompassDirection | "";
}

/**
 * hook to provide startDrag/endDrag functions with a previewStyle to render the previewWindow
 * @param ref ref to a DOM element
 * @param view
 * @returns
 */
export const useResizable = (ref: React.RefObject<Container | null>, view: EdstView) => {
  const dispatch = useRootDispatch();
  const {
    setDraggingOutlineVisible,
    setDraggingOutlinePositionAndDimension,
    getDraggingOutlinePositionAndDimension,
    resizeHandler,
    anyDragging,
    setAnyDragging,
  } = useDragContext();
  const [dragging, setDragging] = useState(false);
  const resizeHandlerRef = useRef<null | ((event: MouseEvent) => void)>(null);

  const startResize = useStableCallback(
    async (event: FederatedPointerEvent, anchorX: AnchorDirection, anchorY: AnchorDirection) => {
      if (!anyDragging && ref.current && event.button === TBP) {
        event.stopPropagation();
        dispatch(setSelectedViewOption(null));
        const bounds = ref.current.getBounds();
        if (window.__TAURI__) {
          await WebviewWindow.getCurrent().setCursorGrab(true);
        }
        setDraggingOutlinePositionAndDimension(bounds.x, bounds.y, bounds.width, bounds.height);
        setDraggingOutlineVisible(true);
        setDragging(true);
        setAnyDragging(true);
        const cursorDirection = getCursorDirectionFromAnchor(anchorX, anchorY);
        if (cursorDirection) {
          const cursorCenter = resizeCursorCenterMap[cursorDirection].join(" ");
          document.body.style.cursor = `url(/cursors/EDSTResizeArrow${cursorDirection}.png) ${cursorCenter}, auto`;
        }
        resizeHandlerRef.current = (event) => resizeHandler(event, anchorX, anchorY, bounds.rectangle);
        window.addEventListener("mousemove", resizeHandlerRef.current, {
          passive: true,
          capture: true,
        });
      }
    },
  );

  const stopResize = useStableCallback(() => {
    document.body.style.cursor = "";
    if (resizeHandlerRef.current) {
      window.removeEventListener("mousemove", resizeHandlerRef.current, { capture: true });
    }
    if (dragging) {
      const { x, y, width, height } = getDraggingOutlinePositionAndDimension();
      const newPos = { x, y };
      dispatch(setViewPosition({ view, pos: newPos }));
      dispatch(setViewDimension({ view, dim: { width, height } }));
      dispatch(setIsFullscreen({ view, value: false }));
      if (window.__TAURI__) {
        void WebviewWindow.getCurrent().setCursorGrab(false);
      }
      setAnyDragging(false);
      setDragging(false);
      setDraggingOutlineVisible(false);
    }
  });

  usePixiMouseEventListener(
    (event) => {
      if (event.button === TBP && dragging) {
        event.stopImmediatePropagation();
        void stopResize();
      }
    },
    undefined,
    true,
    "mouseup",
  );

  return { startResize };
};
