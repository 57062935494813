import React, { useRef } from "react";
import type { ColorSource, Graphics as PixiGraphics } from "pixi.js";
import { colorNameMap, EramInput, useFocused } from "@poscon/shared-frontend";
import { bottomRightBorderColor, defaultTint, font2Dimension, topLeftBorderColor } from "~/utils/constants";

export const defaultInputBoxPadding = 4;

type EdstInputProps = {
  name?: string;
  value: string;
  x?: number;
  y?: number;
  width: number;
  height?: number;
  zIndex?: number;
  chunkSize?: number;
  isActive: boolean;
  cursorPosition: number;
  textColor?: ColorSource;
  padding?: number;
};
export const EdstInput = ({ x, y, zIndex, padding = defaultInputBoxPadding, ...props }: EdstInputProps) => {
  const ref = useRef<PixiGraphics>(null);
  const focused = useFocused(ref);
  return (
    <container zIndex={zIndex} x={x} y={y} eventMode="static" ref={ref}>
      <graphics
        zIndex={0}
        eventMode="static"
        draw={(graphics) => {
          graphics.clear();
          if (focused) {
            graphics
              .rect(0, 0, props.width + padding * 2, (props.height ?? font2Dimension.height) + padding * 2)
              .stroke({ width: 1, color: colorNameMap.white });
          }
          graphics.setStrokeStyle({});
          graphics
            .rect(0, 0, props.width + padding * 2, (props.height ?? font2Dimension.height) + padding * 2)
            .fill(0)
            .stroke({ width: 2, color: bottomRightBorderColor });
          graphics.setStrokeStyle({});
          graphics
            .moveTo(props.width + padding * 2, 0)
            .lineTo(props.width + padding * 2, (props.height ?? font2Dimension.height) + padding * 2)
            .lineTo(0, (props.height ?? font2Dimension.height) + padding * 2)
            .stroke({ width: 2, color: topLeftBorderColor });
        }}
      />
      <EramInput x={padding} y={padding} zIndex={1} tint={defaultTint} {...props} />
    </container>
  );
};
