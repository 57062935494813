import React from "react";
import { defaultTint, font2, font2Dimension } from "~/utils/constants";
import type { ColorSource, FederatedEventHandler, Graphics as PixiGraphics } from "pixi.js";
import { Rectangle } from "pixi.js";
import { EramFontName, getBitmapTextStyles, useFocused, useHitArea } from "@poscon/shared-frontend";

type TableFieldProps = {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  minWidth?: number;
  maxWidth?: number;
  fontName?: EramFontName;
  text?: string;
  tint?: ColorSource;
  fillColor?: ColorSource;
  onmousedown?: FederatedEventHandler;
  disabled?: boolean;
  borderTint?: ColorSource;
  hoverTint?: ColorSource;
  selected?: boolean;
  textX?: number;
  children?: React.ReactNode;
};

export const TableField = ({
  x = 0,
  y = 0,
  text: _text = "",
  textX,
  fontName = font2,
  minWidth = 0,
  width: _width = font2Dimension.width * _text.length + 3,
  maxWidth = _width,
  height = font2Dimension.height + 2,
  tint = defaultTint,
  fillColor = 0x000000,
  borderTint,
  hoverTint = 0xf0f0f0,
  selected,
  disabled,
  onmousedown,
  children,
}: TableFieldProps) => {
  const ref = React.useRef<PixiGraphics>(null);
  const focused = useFocused(ref);
  let text = fontName?.startsWith("eramText") ? _text.toUpperCase() : _text;
  const width = Math.min(Math.max(_width, minWidth), maxWidth);

  const hitAreaRef = useHitArea(0, 0, width, height);

  if (text.length * font2Dimension.width > width) {
    text = text.slice(0, Math.floor(width / font2Dimension.width));
  }

  return (
    <container x={x} y={y} sortableChildren eventMode={disabled ? "none" : "passive"}>
      <graphics
        zIndex={0}
        ref={ref}
        eventMode="static"
        draw={(graphics) => {
          graphics.clear();
          graphics
            .rect(0, 0, width, height)
            .fill({ color: selected ? tint : fillColor, alpha: selected ? 1 : 0 });
          if (focused) {
            graphics.stroke({ width: 1, color: hoverTint });
          } else if (borderTint) {
            graphics.stroke({ width: 1, color: borderTint });
          }
        }}
        hitArea={hitAreaRef.current}
        onMouseDown={onmousedown}
        onRightDown={onmousedown}
      />
      {children}
      {!children && text && (
        <bitmapText
          eventMode="none"
          text={text}
          x={textX ?? Math.floor(width / 2)}
          roundPixels
          anchor={textX !== undefined ? undefined : { x: 0.5, y: 0 }}
          y={2}
          style={{ ...getBitmapTextStyles(fontName), fill: selected ? fillColor : tint }}
        />
      )}
    </container>
  );
};
