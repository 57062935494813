import type { RootState } from "~redux/store";
import type { EdstView } from "types/edstView";
import { initialMraOptions } from "components/initialViewOptions/initialMraOptions";
import { initialMcaOptions } from "components/initialViewOptions/initialMcaOptions";
import { initialAltimSetOptions } from "components/initialViewOptions/InitialAltimSetOptions";
import { initialStatusOptions } from "components/initialViewOptions/initialStatusOptions";
import { initialWxOptions } from "components/initialViewOptions/initialWxOptions";
import { initialGIOptions } from "~/components/views/initialViewOptions/initialGIOptions";
import type {
  ViewButtonOption as _ViewButtonOption,
  ViewCounterOption as _ViewCounterOption,
  ViewOptionMapValue as _ViewOptionMapValue,
  ViewToggleOption as _ViewToggleOption,
} from "@poscon/shared-frontend";
import {
  cpdlcAdvView,
  cpdlcHistView,
  cpdlcMsgOutView,
  initialCpdlcAdvOptions,
  initialCpdlcHistViewOptions,
  initialMsgOutOptions,
  viewOptionFactory,
} from "@poscon/shared-frontend";

const initialState = {
  [cpdlcAdvView]: initialCpdlcAdvOptions,
  [cpdlcHistView]: initialCpdlcHistViewOptions,
  [cpdlcMsgOutView]: initialMsgOutOptions,
  GI: initialGIOptions,
  ALTIM_SET: initialAltimSetOptions,
  MESSAGE_COMPOSE_AREA: initialMcaOptions,
  MESSAGE_RESPONSE_AREA: initialMraOptions,
  STATUS: initialStatusOptions,
  WX_REPORT: initialWxOptions,
};

export type ViewOptionState = typeof initialState & Partial<Record<EdstView, unknown>>;

export const { viewOptionSlice, viewOptionSelector, setViewOption, deltaViewOption, setViewOptionState } =
  viewOptionFactory<EdstView, ViewOptionState>(initialState);

export const reducer = viewOptionSlice.reducer;

export type ViewCounterOption = _ViewCounterOption<RootState>;
export type ViewToggleOption = _ViewToggleOption<RootState>;
export type ViewButtonOption = _ViewButtonOption<RootState>;
export type ViewOptionMapValue = _ViewOptionMapValue<RootState>;

export {
  brightOption,
  counterOption,
  fontOption,
  linesOption,
  toggleOption,
  buttonOption,
} from "@poscon/shared-frontend";
