import React, { useRef, useState } from "react";
import { useRootSelector } from "~redux/hooks";
import { edsmAreasSelector, viewSelector } from "~redux/slices/edstSlice";
import { useDragging } from "hooks/useDragging";
import { colorNameMap, eramTextDimensionMap, MenuElement, useCenterCursor } from "@poscon/shared-frontend";
import type { Container as PixiContainer } from "pixi.js";
import { useZIndex } from "hooks/useZIndex";
import { MenuTitleBar } from "components/utils/MenuTitleBar";
import { eramHubConnection } from "~/eramHubConnection";
import { GIMessage } from "@poscon/shared-types";
import { EramArea } from "@poscon/shared-types/eram";

const menu = "GI_FORWARD_MENU" as const;

const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[2];

const width = 75 * fontWidth;

type GIForwardMenuAreaProps = {
  msg: GIMessage;
  x: number;
  y: number;
  area: EramArea;
  selectedPositions: string[];
  selectPosition: (positionId: string) => void;
  selectedAreas: string[];
  selectArea: (area: EramArea) => void;
};

const GIForwardMenuArea = ({
  x,
  y,
  msg,
  area,
  selectedPositions,
  selectPosition,
  selectedAreas,
  selectArea,
}: GIForwardMenuAreaProps) => {
  return (
    <container x={x} y={y}>
      <MenuElement
        x={4}
        y={4}
        text={area.areaName}
        fillColor={0}
        borderColor={0}
        selectedFillColor={colorNameMap.lightGrey}
        selectReverseVideo
        selected={selectedAreas.includes(area.areaName)}
        onmousedown={() => {
          if (!selectedAreas.includes(area.areaName)) {
            selectArea(area);
          }
        }}
      />
      <container x={4} y={fontHeight + 8}>
        {area.sectorIds.map((sectorId, index) => {
          const tint = msg.forwardedToPositions.includes(sectorId)
            ? colorNameMap.mediumGrey
            : colorNameMap.lightGrey;
          const x = (index % 3) * fontWidth * 4;
          const y = Math.floor(index / 3) * (fontHeight + 4);
          const selected = selectedAreas.includes(area.areaName) || selectedPositions.includes(sectorId);
          return (
            <MenuElement
              key={sectorId}
              x={x}
              y={y}
              text={sectorId}
              tint={tint}
              fillColor={0}
              borderColor={0}
              selected={selected}
              selectedFillColor={colorNameMap.lightGrey}
              selectReverseVideo
              onmousedown={() => {
                if (!selected) {
                  selectPosition(sectorId);
                }
              }}
            />
          );
        })}
      </container>
    </container>
  );
};

type GIForwardMenuProps = {
  msg: GIMessage;
  closeMenu: () => void;
};

export const GIForwardMenu = ({ msg, closeMenu }: GIForwardMenuProps) => {
  const areas = useRootSelector(edsmAreasSelector);
  const viewProps = useRootSelector((state) => viewSelector(state, menu));
  const [selectedAreas, setSelectedAreas] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);

  const ref = useRef<PixiContainer>(null);
  const zIndex = useZIndex(menu, ref);
  useCenterCursor(ref);
  const { startDrag } = useDragging(ref, menu, "mouseup");

  const sortedAreas = areas.toSorted((a, b) => a.areaNumber - b.areaNumber);

  const selectArea = (area: EramArea) => {
    if (!selectedAreas.includes(area.areaName)) {
      setSelectedAreas((prev) => [...prev, area.areaName]);
      eramHubConnection.emit(
        "forwardGiMessage",
        msg.id,
        area.sectorIds.filter((sectorId) => !selectedPositions.includes(sectorId)),
      );
    }
  };

  const selectPosition = (pos: string) => {
    if (!selectedPositions.includes(pos)) {
      setSelectedPositions((prev) => [...prev, pos]);
      eramHubConnection.emit("forwardGiMessage", msg.id, [pos]);
    }
  };

  const height = (fontHeight + 1) * 6 * Math.floor(areas.length / 4);

  return (
    <container
      ref={ref}
      x={viewProps.position.x}
      y={viewProps.position.y}
      zIndex={zIndex}
      eventMode="static"
      sortableChildren
    >
      <MenuTitleBar
        title="SELECT SECTOR"
        width={width}
        onTitleMouseDown={startDrag}
        view={menu}
        closeMenu={closeMenu}
      />
      <container y={fontHeight + 2}>
        <graphics
          draw={(graphics) => {
            graphics.clear();
            graphics.rect(0, 0, width, height).fill(0).stroke({ width: 1, color: colorNameMap.grey });
          }}
        />
        <container y={4}>
          {sortedAreas.map((a, index) => {
            const x = (index % 4) * 20 * fontWidth;
            const y = (fontHeight + 2) * 6 * Math.floor(index / 4);
            return (
              <GIForwardMenuArea
                key={a.areaName}
                msg={msg}
                x={x}
                y={y}
                area={a}
                selectedPositions={selectedPositions}
                selectPosition={selectPosition}
                selectedAreas={selectedAreas}
                selectArea={selectArea}
              />
            );
          })}
        </container>
      </container>
    </container>
  );
};
