import React, { useRef } from "react";
import { useRootSelector } from "~redux/hooks";
import { View } from "components/utils/View";
import { sectorIdSelector } from "@poscon/shared-frontend";

const view = "RESTRICTIONS";

const charWidth = 90;

type RestrictionRowProps = {
};
const RestrictionRow = ({ }: RestrictionRowProps) => {

  return null;
};

export const RestrictionsView = () => {
  const sectorId = useRootSelector(sectorIdSelector);
  const restrictions: any[] = [];

  return (
    <View
      title="RESTRICTIONS"
      width={charWidth}
      height={0}
      view={view}
    >
    </View>
  );
};
