import type { EdstAselMenu } from "types/edstView";
import type { AclRowField, FlightplanId, DepRowField, PlanRowField } from "@poscon/shared-types/eram";
import { aselSelector, setAsel } from "~redux/slices/edstSlice";
import type { RootThunkAction } from "~redux/store";

export const aircraftSelect = (
  fpId: FlightplanId,
  field: AclRowField | DepRowField | PlanRowField | null,
  menu?: EdstAselMenu,
): RootThunkAction => {
  return (dispatch, getState) => {
    const state = getState();
    const asel = aselSelector(state);
    if (asel?.fpId === fpId && asel?.field === field && !menu) {
      dispatch(setAsel(null));
    } else {
      dispatch(setAsel({ fpId, field, menu }));
    }
  };
};
