import React, { useEffect, useRef, useState } from "react";
import type { Graphics as PixiGraphics } from "pixi.js";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { useDragging } from "hooks/useDragging";
import { ViewMenu } from "components/utils/ViewMenu";
import {
  brightOption,
  buttonOption,
  counterOption,
  fontOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import { pushZStack, viewPositionSelector } from "~redux/slices/edstSlice";
import { useWindowSize } from "usehooks-ts";
import { chunkRows } from "@poscon/shared-types";
import { useZIndex } from "hooks/useZIndex";
import { ViewOptionContextProvider } from "contexts/viewOptionContext";
import type { EramFontSize } from "@poscon/shared-frontend";
import {
  getBitmapTextStyles,
  mraMsgSelector,
  setMraMessage,
  useViewOptionSelected,
  setSelectedViewOption,
  baseBorderColor,
  colorNameMap,
  computeColor,
  eramFontNameMap,
  eramTextDimensionMap,
  ScrollBar,
  TBE,
  TBP,
  usePixiMouseEventListener,
} from "@poscon/shared-frontend";
import { layerZIndexMap } from "~/layerZIndexMap";

const view = "MESSAGE_RESPONSE_AREA";

const optionMap = {
  width: counterOption(view, "width", "WIDTH", 30, 50, 8, "delta", 20),
  font: fontOption(view),
  bright: brightOption(view),
  clear: buttonOption(view, "CLEAR", 5, () => setMraMessage([])),
};

export const MessageResponseArea = () => {
  const dispatch = useRootDispatch();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const ref = useRef<PixiGraphics>(null);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, "MESSAGE_RESPONSE_AREA"));
  const _pos = useRootSelector((state) => viewPositionSelector(state, "MESSAGE_RESPONSE_AREA"));
  const [pageNumber, setPageNumber] = useState(0);
  const msgPages = useRootSelector(mraMsgSelector);
  const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[viewOptions.font as EramFontSize];
  const zIndex = useZIndex(view, ref);

  const rows = msgPages[pageNumber]?.flatMap((r) => chunkRows(r, viewOptions.width)) ?? [];
  const width = fontWidth * viewOptions.width + 4 + (msgPages.length > 1 ? fontWidth + 2 : 0);
  const height = fontHeight * Math.max(4, rows.length) + 2;

  const { startDrag } = useDragging(ref, "MESSAGE_RESPONSE_AREA");
  const { selected: showOptions } = useViewOptionSelected(view);

  useEffect(() => {
    dispatch(pushZStack(view));
    setPageNumber(0);
  }, [dispatch, msgPages]);

  usePixiMouseEventListener((event) => {
    switch (event.button) {
      case TBE:
        dispatch(setSelectedViewOption(view));
        break;
      case TBP:
        void startDrag(event);
        break;
    }
  }, ref);

  const pos = {
    x: Math.min(_pos.x, windowWidth - width - 2),
    y: Math.min(_pos.y, windowHeight - height - 2),
  };

  const onScrollClick = (sign: 1 | -1) => {
    const newPageNumber = pageNumber + sign;
    if (newPageNumber >= 0 && newPageNumber < msgPages.length) {
      setPageNumber(newPageNumber);
    }
  };

  const fontName = eramFontNameMap[viewOptions.font as EramFontSize];
  const tint = computeColor(colorNameMap.white, viewOptions.bright / 100);

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <container label="MRA" x={pos.x} y={pos.y} sortableChildren zIndex={zIndex}>
        <graphics
          ref={ref}
          eventMode="static"
          draw={(graphics) => {
            graphics.clear();
            graphics.rect(0, 0, width, height).fill(0).stroke({ width: 1, color: baseBorderColor });
          }}
        />
        <bitmapText
          zIndex={1}
          text={rows.join("\n")}
          x={1}
          y={1}
          eventMode="none"
          style={{ ...getBitmapTextStyles(fontName), fill: tint }}
        />
        {msgPages.length > 1 && (
          <ScrollBar
            x={width - fontWidth - 2}
            y={1}
            scrollUp={() => onScrollClick(-1)}
            scrollDown={() => onScrollClick(1)}
            scrollUpDisabled={pageNumber === 0}
            scrollDownDisabled={pageNumber === msgPages.length - 1}
            height={height - 3}
            tint={tint}
            disabledTint={computeColor(colorNameMap.grey, viewOptions.bright / 100)}
            borderTint={baseBorderColor}
          />
        )}
      </container>
      {showOptions && width && (
        <container x={pos.x} y={pos.y} sortableChildren zIndex={layerZIndexMap.viewMenu}>
          <ViewMenu view={view} parentWidth={width} options={optionMap} initialFocusOverrideIndex={3} />
        </container>
      )}
    </ViewOptionContextProvider>
  );
};
