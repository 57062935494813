import {
  advCreationTemplateView,
  advDeleteView,
  advFreetextTemplateView,
  advUplinkTemplateView,
  aircraftMenuList,
  cpdlcAdvView,
  cpdlcHistView,
  cpdlcMiniMOView,
  cpdlcMsgOutView,
  eligibilityOverrideView,
} from "@poscon/shared-frontend";

export const aselMenus = [
  ...aircraftMenuList,
  "PLAN_OPTIONS",
  // PROMPTS
  "PREV_ROUTE_MENU",
  "CANCEL_HOLD_MENU",
  "CHANGE_DEST_MENU",
  "PO_MENU",
  cpdlcMiniMOView,
] as const;

export const edstMenus = [
  "ACL_SORT_MENU",
  "DEP_SORT_MENU",
  "TOOLS_MENU",
  "TEMPLATE_MENU",
  "EQUIPMENT_TEMPLATE_MENU",
  "GPD_MAP_OPTIONS_MENU",
  "X_RES_MENU",
  advCreationTemplateView,
  advUplinkTemplateView,
  advDeleteView,
  advFreetextTemplateView,
  "ADD_AIRSPACE_ALT_MENU",
  "SETTINGS_MENU",
  "WIND_ALT_MENU",
  "GI_FORWARD_MENU",
  ...aselMenus,
] as const satisfies string[];

export const edstViews = [
  "ACL",
  "DEP",
  "GPD",
  "PLANS_DISPLAY",
  "MESSAGE_COMPOSE_AREA",
  "MESSAGE_RESPONSE_AREA",
  "STATUS",
  "OUTAGE",
  "WX_REPORT",
  "UA",
  "SIGMETS",
  "RESTRICTIONS",
  "AIRPORT_STREAM_FILTER",
  "GI",
  "ADSB",
  "MSG",
  "WIND",
  "ALTIM_SET",
  "FLIGHT_EVENT",
  "MORE",
  cpdlcAdvView,
  cpdlcHistView,
  cpdlcMsgOutView,
  eligibilityOverrideView,
  "AIRSPACE_STATUS",
  ...edstMenus,
] as const;

export function isAselMenu(menu: string): menu is EdstAselMenu {
  return aselMenus.includes(menu as any);
}

export type EdstView = (typeof edstViews)[number];
export type EdstAselMenu = (typeof aselMenus)[number];
export type EdstMenu = (typeof edstMenus)[number];
