import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { View } from "components/utils/View";
import {
  brightOption,
  buttonOption,
  counterOption,
  fontOption,
  linesOption,
  viewOptionSelector,
} from "~redux/slices/viewOptionSlice";
import {
  createItemOption,
  ViewItemOptionContainer,
  colorNameMap,
  computeColor,
  eramFontDimensionMap,
  eramFontNameMap,
  useAltimeter,
  useCurrentTime,
  useFocused,
  useViewOptionSelected,
  altimeterAirportsSelector,
  getBitmapTextStyles,
  useHitArea,
} from "@poscon/shared-frontend";
import type { EramFontSize } from "@poscon/shared-frontend";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import type { FederatedPointerEvent, Graphics } from "pixi.js";
import { Rectangle } from "pixi.js";
import { useViewOptions, ViewOptionContextProvider } from "contexts/viewOptionContext";
import { formatUtcTime } from "@poscon/shared-types";
import { stringToParsedTokenArray } from "@poscon/shared-types/eram";

const view = "ALTIM_SET";

const optionMap = {
  lines: linesOption(view, 2, 21),
  column: counterOption(view, "column", "COLUMN", 1, 10, 9),
  font: fontOption(view),
  bright: brightOption(view),
  template: buttonOption(view, "TEMPLATE", 8),
};

type AltimeterRowProps = {
  stationCode: string;
  x: number;
  y: number;
};
export const AltimeterRow = ({ stationCode, x, y }: AltimeterRowProps) => {
  const dispatch = useRootDispatch();
  const { selected, toggleViewOption } = useViewOptionSelected(`${view}/${stationCode}`);

  const ref = useRef<Graphics>(null);
  const focused = useFocused(ref);
  const viewOptions = useViewOptions(view);
  const itemOptions = [
    createItemOption(`DELETE ${stationCode}`, () => {
      dispatch(processEramMessage(stringToParsedTokenArray(`QD ${stationCode}`)));
    }),
  ];

  const fontSize = viewOptions.font as EramFontSize;
  const fontFamily = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontFamily];

  const { altimeter, timeRecorded } = useAltimeter(stationCode);

  const now = useCurrentTime();
  const observationThreshold1 = new Date(timeRecorded + 3900e3);
  const observationThreshold2 = new Date(timeRecorded + 7200e3);

  const width = 17 * fontDimension.width + 4;
  const height = fontDimension.height + 2;

  const hitAreaRef = useHitArea(0, 0, width, height);

  const alpha = (viewOptions.bright * 0.8) / 100 + 0.2;

  const tint = selected ? 0 : computeColor(colorNameMap.white, viewOptions.bright / 100);
  const style = { ...getBitmapTextStyles(fontFamily), fill: tint };

  const bgColor = computeColor(selected ? colorNameMap.grey : colorNameMap.black, selected ? alpha : 1);

  return (
    <container x={x} y={y} zIndex={selected ? 2 : 1}>
      <container sortableChildren>
        <graphics
          ref={ref}
          hitArea={hitAreaRef.current}
          eventMode="static"
          zIndex={1}
          draw={(graphics) => {
            graphics.clear();
            graphics
              .rect(0, 0, width, height)
              .fill({ color: bgColor, alpha: selected ? 1 : 0 })
              .stroke({
                width: 1,
                color: focused ? 0xffffff : selected ? bgColor : 0x000000,
              });
            if (observationThreshold1 < now) {
              graphics
                .moveTo(fontDimension.width * 7, fontDimension.height)
                .lineTo(fontDimension.width * 11, fontDimension.height)
                .stroke({ width: 1, color: tint });
            }
            if (altimeter && parseInt(altimeter, 10) < 2992) {
              graphics
                .moveTo(fontDimension.width * 13, fontDimension.height)
                .lineTo(fontDimension.width * 16, fontDimension.height)
                .stroke({ width: 1, color: tint });
            }
          }}
          onMouseDown={(event: FederatedPointerEvent) => {
            toggleViewOption();
          }}
        />
        <bitmapText
          x={fontDimension.width * 2}
          y={2}
          zIndex={3}
          text={stationCode}
          eventMode="none"
          style={style}
        />
        {altimeter && (
          <bitmapText
            x={fontDimension.width * 7}
            y={2}
            zIndex={3}
            text={formatUtcTime(timeRecorded)}
            eventMode="none"
            style={style}
          />
        )}
        <bitmapText
          x={fontDimension.width * 13}
          y={2}
          zIndex={3}
          text={!altimeter || observationThreshold2 < now ? "-M-" : altimeter.slice(1)}
          eventMode="none"
          style={style}
        />
      </container>
      {selected && <ViewItemOptionContainer xOffset={width} options={itemOptions} />}
    </container>
  );
};

export const AltimSet = () => {
  const airports = useRootSelector(altimeterAirportsSelector);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const fontDimension = eramFontDimensionMap[eramFontNameMap[viewOptions.font as EramFontSize]];

  const height = airports.length > 0 ? (fontDimension.height + 4) * airports.length + 8 : 0;

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View width={20} view="ALTIM_SET" optionMap={optionMap} height={height}>
        {airports.length > 0 && (
          <container x={2} y={4}>
            {airports.map(({ station }, index) => (
              <AltimeterRow
                key={`${station}-${index}`}
                stationCode={station}
                x={8}
                y={(fontDimension.height + 6) * index}
              />
            ))}
          </container>
        )}
      </View>
    </ViewOptionContextProvider>
  );
};
