import React, { useRef } from "react";
import { useRootSelector } from "~redux/hooks";
import { View } from "components/utils/View";
import { sectorIdSelector } from "@poscon/shared-frontend";

const view = "AIRPORT_STREAM_FILTER";

const charWidth = 90;

type AsfRowProps = {
};
const AirportStreamFilterRow = ({ }: AsfRowProps) => {

  return null;
};

export const AirportStreamFilterView = () => {
  const sectorId = useRootSelector(sectorIdSelector);
  const restrictions: any[] = [];

  return (
    <View
      title={`Airport Stream Filter Status Display - ${sectorId?.split(".").at(-1) ?? "00"}`}
      width={charWidth}
      height={0}
      view={view}
    >
    </View>
  );
};
