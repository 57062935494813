import React, { useEffect, useRef, useState, useSyncExternalStore } from "react";
import type { Container, FederatedPointerEvent, Sprite } from "pixi.js";
import { font2, font2Dimension } from "~/utils/constants";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import {
  closeView,
  translateWindGridCenter,
  setWindgridScaleToRange,
  setWindGridShowTemp,
  windgridStateSelector,
} from "~redux/slices/edstSlice";
import {
  colorNameMap,
  eramFontNameMap,
  getBitmapTextStyles,
  TBP,
  useHitArea,
  useOnMount,
} from "@poscon/shared-frontend";
import { titleBarHeight } from "components/ViewTitleBar";
import { mod } from "@poscon/shared-types";
import type { Coordinate } from "@poscon/shared-types";
import { windGridStore } from "~/windGridStore";
import { EdstButton } from "components/utils/EdstButton";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { LogicalPosition } from "@tauri-apps/api/window";
import { Rectangle, Texture } from "pixi.js";
import { openViewThunk } from "~redux/thunks/openViewThunk";
import { useCursorContext } from "contexts/cursorContext";
import { env } from "~/env";

const windgridWorker = new Worker(new URL("../../../workers/windgrid.ts", import.meta.url), {
  type: "module",
});

// const geomaps = await eramHubConnection.getGeomaps("KZDC");
const geomap = null; // geomaps.find((m) => m.GeomapId === "TMU" && m.MapGroupId === 35 && m.MapObjectType === "SECTOR");

export const defaultLineStyle = {
  width: 1,
  alpha: 1,
  color: 0xf0f0f0,
  alignment: 1,
  native: true,
};

type WindBarbProps = {
  direction: number;
  speed: number;
  text?: string;
  x?: number;
  y?: number;
};
const WindArrow = ({ speed, direction, text, x = 0, y = 0 }: WindBarbProps) => {
  const length = Math.max(12, Math.round((speed * 2) / 3));
  return (
    <container x={x} y={y}>
      <graphics
        angle={mod(direction + 90, 360)}
        draw={(graphics) => {
          graphics.clear();
          graphics.circle(0, 0, 4).fill(0xbcbcbc);
          if (length > 0) {
            graphics.moveTo(0, 0).lineTo(length, 0);
            graphics
              .moveTo(length - 8, -5)
              .lineTo(length, 0)
              .lineTo(length - 8, 5)
              .stroke({ width: 2, color: 0xbcbcbc });
          }
        }}
      />
      <bitmapText
        text={text}
        x={0}
        y={5}
        style={{ ...getBitmapTextStyles(eramFontNameMap[3]), fill: 0xbcbcbc }}
      />
    </container>
  );
};

type Windgrid = [number, number, number | null][];
type WindgridArgs = {
  alt: number;
  beginLat: number;
  beginLon: number;
  endLat: number;
  endLon: number;
  stepLat: number;
  stepLon: number;
  when: number;
};

const windgridUrl = env.VITE_WINDGRID_URL;
const tempgridUrl = env.VITE_TEMPGRID_URL;

const y = font2Dimension.height * 3 + 8;

const stepLon = 0.5;
const stepLat = 0.5;

export const WindGridBody = () => {
  const dispatch = useRootDispatch();
  const ref = useRef<Container>(null);
  const maskRef = useRef<Sprite>(null);
  const { setShowRecenterCursor } = useCursorContext();
  const windGridStoreValue = useSyncExternalStore(windGridStore.subscribe, windGridStore.getState);
  const windgridParams = useRootSelector(windgridStateSelector);
  // [speed, direction, temp?][]
  const [windGridData, setWindGridData] = useState<{ grid: Windgrid; params: WindgridArgs | null }>({
    grid: [],
    params: null,
  });
  const [recentering, setRecentering] = useState(false);

  useOnMount(() => {
    windgridWorker.onmessage = (e) => {
      if (e.data.type === "result") {
        setWindGridData({ grid: e.data.result, params: e.data.params });
      }
    };
  });

  useEffect(() => {
    setShowRecenterCursor(recentering);
    if (window.__TAURI__ && maskRef.current && recentering) {
      const rect = maskRef.current.getBounds();
      const pos = maskRef.current.getGlobalPosition();
      const newCursorPos = {
        x: pos.x + rect.width / 2,
        y: pos.y + rect.height / 2,
      };
      void WebviewWindow.getCurrent().setCursorPosition(
        new LogicalPosition(newCursorPos.x - 1, newCursorPos.y - 1),
      );
    }
  }, [recentering, setShowRecenterCursor]);

  useEffect(() => {
    if (windGridStore.rect.width === 0 || windGridStore.rect.height === 0) return;
    const topLeftPx = [-windGridStoreValue.rect.width, -windGridStoreValue.rect.height] satisfies Coordinate;
    const bottomRightPx = [windGridStore.rect.width * 2, windGridStore.rect.height * 2] satisfies Coordinate;

    const topLeft = windGridStoreValue.getLonLatFromPixelCoord(topLeftPx);
    const bottomRight = windGridStoreValue.getLonLatFromPixelCoord(bottomRightPx);

    const beginLon = Math.floor(topLeft[0]);
    const beginLat = Math.floor(bottomRight[1]);
    const endLon = Math.ceil(bottomRight[0]) + stepLon;
    const endLat = Math.ceil(topLeft[1]) + stepLat;

    const when = new Date();
    // TODO: uncomment to get latest forecast without interpolation
    // when.setUTCHours(Math.floor(when.getUTCHours() / 3) * 3, 0, 0, 0);
    windgridWorker.postMessage({
      type: "fetch",
      windgridUrl,
      tempgridUrl,
      params: {
        beginLon,
        beginLat,
        endLon,
        endLat,
        stepLon,
        stepLat,
        alt: windgridParams.altitude * 100,
        when: when.getTime(),
      },
    });
  }, [windGridStoreValue, windGridStoreValue.rect, windgridParams.altitude]);

  const { width, height } = windGridStoreValue.rect;
  const containerHitAreaRef = useHitArea(0, 0, width, windGridStoreValue.rect.height);

  const params = windGridData.params;

  const timeRecorded = params !== null ? new Date(params.when) : null;

  const numX = params !== null ? (params.endLon - params.beginLon) / stepLon : 2;

  return (
    <container y={titleBarHeight + 3}>
      <container>
        <graphics
          draw={(graphics) => {
            graphics.clear();
            graphics
              .moveTo(1, font2Dimension.height * 3 + 8)
              .lineTo(width, font2Dimension.height * 3 + 8)
              .stroke({ width: 1, color: colorNameMap.grey });
          }}
        />
        <EdstButton
          x={4 + font2Dimension.width * 2}
          text="RECENTER"
          onmousedown={() => setRecentering(true)}
        />
        <EdstButton
          x={4 + font2Dimension.width * 10 + 8 * 2}
          text={`RANGE ${windGridStoreValue.range}`}
          onmousedown={(event) => {
            dispatch(
              setWindgridScaleToRange(
                windGridStoreValue.range + (event.button === TBP ? -25 : 25),
                windGridStoreValue.range,
                windGridStoreValue.rect,
              ),
            );
          }}
        />
        <EdstButton
          x={4 + font2Dimension.width * 19 + 8 * 4}
          text="ALTITUDE..."
          onmousedown={(e) => {
            dispatch(openViewThunk("WIND_ALT_MENU", e.target as Container));
          }}
        />
        <EdstButton
          x={4 + font2Dimension.width * 30 + 8 * 6}
          text={windgridParams.showTemp ? "HIDE TEMP" : "SHOW TEMP"}
          onmousedown={() => dispatch(setWindGridShowTemp(!windgridParams.showTemp))}
        />
        <EdstButton
          x={4 + font2Dimension.width * 39 + 8 * 8}
          text="EXIT"
          onmousedown={() => dispatch(closeView("WIND"))}
        />
        <bitmapText
          x={4 + font2Dimension.width * 2}
          y={font2Dimension.height + 7}
          text={`ALT: ${windgridParams.altitude * 100} FT.`}
          style={{ ...getBitmapTextStyles(font2), fill: 0xbcbcbc }}
        />
        {timeRecorded && (
          <>
            <bitmapText
              x={4 + font2Dimension.width * 20}
              y={font2Dimension.height + 7}
              text={`DATE: ${(timeRecorded.getUTCMonth() + 1).toString().padStart(2, "0")}/${timeRecorded.getUTCDate().toString().padStart(2, "0")}/${timeRecorded.getUTCFullYear()}`}
              style={{ ...getBitmapTextStyles(font2), fill: 0xbcbcbc }}
            />
            <bitmapText
              x={4 + font2Dimension.width * 40}
              y={font2Dimension.height + 7}
              text={`TIME: ${timeRecorded.getUTCHours().toString().padStart(2, "0")}:${timeRecorded.getUTCMinutes().toString().padStart(2, "0")}`}
              style={{ ...getBitmapTextStyles(font2), fill: 0xbcbcbc }}
            />
          </>
        )}
      </container>
      <sprite y={y} ref={maskRef} texture={Texture.WHITE} width={width} height={height} />
      <container
        hitArea={containerHitAreaRef.current}
        mask={maskRef.current}
        eventMode="static"
        y={y}
        label="WIND_GRID_CONTAINER"
        ref={ref}
        onMouseDown={(event: FederatedPointerEvent) => {
          if (recentering) {
            const localPos = event.getLocalPosition(ref.current!)!;
            const diff = [
              (localPos.x - windGridStore.rect.width / 2) / windgridParams.scale,
              (localPos.y - windGridStore.rect.height / 2) / windgridParams.scale,
            ] satisfies Coordinate;
            dispatch(translateWindGridCenter(diff));
            setRecentering(false);
          }
        }}
      >
        {recentering && (
          <graphics
            x={Math.floor(width / 2)}
            y={Math.floor(windGridStore.rect.height / 2)}
            draw={(graphics) => {
              graphics.clear();
              graphics
                .moveTo(-11, 0)
                .lineTo(10, 0)
                .moveTo(0, -11)
                .lineTo(0, 10)
                .stroke({ width: 1, color: 0xc0c0c0 });
            }}
          />
        )}
        <container
          scale={windgridParams.scale}
          x={windgridParams.centerOverride[0]}
          y={windgridParams.centerOverride[1]}
        >
          {geomap && (
            <graphics
              draw={(graphics) => {
                graphics.clear();
                // const pathGen = geoPath(windGridStoreValue.projection).context(graphics);
                // geomap.GeomapLines!.forEach((line) => {
                //   pathGen(lineString([line.Start, line.End]).geometry);
                // });
                graphics.stroke({
                  ...defaultLineStyle,
                  color: 0xbcbcbc,
                });
              }}
            />
          )}
        </container>
        {params &&
          windGridData.grid.map(([speed, direction, temp], index) => {
            const lon = params.beginLon + (index % numX) * params.stepLon;
            const lat = params.beginLat + Math.floor(index / numX) * params.stepLat;
            const [x, y] = windGridStoreValue.getPixelCoordFromLonLat([lon, lat])!;
            const shouldDrawCoord = x >= 0 && x <= width && y >= 0 && y <= windGridStore.rect.height;
            return (
              shouldDrawCoord &&
              Number.isFinite(speed) &&
              Number.isFinite(direction) && (
                <WindArrow
                  key={`${lon.toString()}/${lat.toString()}`}
                  x={x}
                  y={y}
                  direction={direction}
                  speed={speed}
                  text={windgridParams.showTemp && temp !== null ? temp.toFixed(0) : speed.toFixed(0)}
                />
              )
            );
          })}
      </container>
    </container>
  );
};
