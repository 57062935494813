import React, { useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { View } from "components/utils/View";
import {
  useViewOptionSelected,
  colorNameMap,
  computeColor,
  eramFontDimensionMap,
  eramFontNameMap,
  useFocused,
  useMetar,
  createItemOption,
  ViewItemOptionContainer,
  EramFontSize,
  metarAirportsSelector,
  getBitmapTextStyles,
  useHitArea,
} from "@poscon/shared-frontend";
import { brightOption, fontOption, linesOption, viewOptionSelector } from "~redux/slices/viewOptionSlice";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import { stringToParsedTokenArray } from "@poscon/shared-types/eram";
import type { FederatedPointerEvent, Graphics } from "pixi.js";
import { Rectangle } from "pixi.js";
import { ViewOptionContextProvider, useViewOptions } from "contexts/viewOptionContext";

const view = "WX_REPORT";

export const optionMap = {
  lines: linesOption(view, 2, 20),
  font: fontOption(view),
  bright: brightOption(view),
};

type MetarRowProps = {
  airport: string;
  x?: number;
  y?: number;
};
export const MetarRow = ({ x, y, airport }: MetarRowProps) => {
  const dispatch = useRootDispatch();
  const ref = useRef<Graphics>(null);
  const focused = useFocused(ref);
  const viewOptions = useViewOptions(view);
  const { selected: _selected, toggleViewOption } = useViewOptionSelected(`${view}/${airport}`);
  const itemOptions = [
    createItemOption(`DELETE ${airport}`, () =>
      dispatch(processEramMessage(stringToParsedTokenArray(`WR ${airport}`))),
    ),
  ];

  const selected = _selected;
  const fontSize = viewOptions.font as EramFontSize;
  const fontFamily = eramFontNameMap[fontSize];
  const fontDimension = eramFontDimensionMap[fontFamily];

  const { metar } = useMetar(airport);

  let text = metar ?? "-M-";
  const lastSpaceIndex = text.lastIndexOf(" ", 35);
  if (lastSpaceIndex !== -1 && text.length > 35) {
    text = text.slice(0, lastSpaceIndex) + "\n" + text.slice(lastSpaceIndex + 1);
  }
  const lines = 1 + Math.floor(text.length / 35);

  const width = 37 * fontDimension.width + 4;
  const height = fontDimension.height * lines + 2;

  const hitAreaRef = useHitArea(0, 0, width, height);

  const alpha = (viewOptions.bright * 0.8) / 100 + 0.2;

  const tint = selected ? 0 : computeColor(colorNameMap.white, viewOptions.bright / 100);

  const bgColor = computeColor(selected ? colorNameMap.grey : colorNameMap.black, selected ? alpha : 1);

  return (
    <container x={x} y={y} sortableChildren>
      <graphics
        ref={ref}
        hitArea={hitAreaRef.current}
        eventMode="static"
        zIndex={1}
        draw={(graphics) => {
          graphics.clear();
          graphics
            .rect(0, 0, width, height)
            .fill({ color: bgColor, alpha: selected ? 1 : 0 })
            .stroke({ width: 1, color: focused ? 0xffffff : selected ? bgColor : 0 });
        }}
        onMouseDown={() => {
          toggleViewOption();
        }}
      />
      <bitmapText
        x={fontDimension.width * 2}
        y={2}
        zIndex={3}
        text={text}
        eventMode="none"
        style={{ ...getBitmapTextStyles(fontFamily), fill: tint }}
      />
      {selected && <ViewItemOptionContainer xOffset={width} options={itemOptions} />}
    </container>
  );
};

export const WxReport = () => {
  const airports = useRootSelector(metarAirportsSelector);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));

  const fontDimension = eramFontDimensionMap[eramFontNameMap[viewOptions.font as EramFontSize]];

  const height = airports.length > 0 ? (fontDimension.height + 2.5) * 2 * airports.length + 8 : 0;

  return (
    <ViewOptionContextProvider options={viewOptions}>
      <View width={40} height={height} view={view} optionMap={optionMap}>
        {airports.length > 0 && (
          <container x={4} y={4}>
            {airports.map((airport, index) => (
              <MetarRow
                key={`${airport}-${index}`}
                airport={airport}
                x={8}
                y={(fontDimension.height * 2 + 4) * index + 2}
              />
            ))}
          </container>
        )}
      </View>
    </ViewOptionContextProvider>
  );
};
