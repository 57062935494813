import React, { useState } from "react";
import { delGIMessage, giMessagesSelector, openView } from "~redux/slices/edstSlice";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { viewOptionSelector } from "~redux/slices/viewOptionSlice";
import { View } from "components/utils/View";
import {
  brightOption,
  buttonOption,
  colorNameMap,
  computeColor,
  eramFontDimensionMap,
  eramFontNameMap,
  EramFontSize,
  fontOption,
  linesOption,
  ScrollBar,
  TBE,
  useScrollProps,
  useViewOptionSelected,
  ViewHeaderProps,
  ViewItem,
  ViewItemOption,
  ViewItemOptionContainer,
  ViewOptionContextProvider,
} from "@poscon/shared-frontend";
import { useViewOptions } from "~/contexts/viewOptionContext";
import { chunkString, GIMessageId, Nullable, SectorGIMessage } from "@poscon/shared-types";
import { eramHubConnection } from "~/eramHubConnection";
import { FederatedEventHandler, FederatedPointerEvent } from "pixi.js";
import { GIForwardMenu } from "./GIFowardMenu";

const view = "GI";

const width = 84;
const rowMaxLen = 80;

const optionMap = {
  lines: linesOption(view, 3),
  font: fontOption(view),
  bright: brightOption(view),
  printAll: buttonOption(view, "PRINT ALL", 9),
};

type GIRowProps = {
  deleteMode: boolean;
  msg: SectorGIMessage;
  deleteModeMouseDown: FederatedEventHandler<FederatedPointerEvent>;
  selectForward: () => void;
  forceSelected?: boolean;
  y?: number;
};
const GIRow = ({ msg, deleteMode, deleteModeMouseDown, forceSelected, selectForward, y = 0 }: GIRowProps) => {
  const dispatch = useRootDispatch();
  const viewOptions = useViewOptions(view);
  const { selected: _selected, openViewOption, closeViewOption } = useViewOptionSelected(`${view}/${msg.id}`);
  const alpha = (viewOptions.bright * 0.8) / 100 + 0.2;

  const selected = _selected || forceSelected;

  const fontSize = viewOptions.font as EramFontSize;
  const fontDimension = eramFontDimensionMap[eramFontNameMap[fontSize]];
  const tint = selected
    ? 0
    : computeColor(msg.acknowledged ? colorNameMap.grey : colorNameMap.white, viewOptions.bright / 100);
  const fillColor = computeColor(selected ? colorNameMap.grey : colorNameMap.black, selected ? alpha : 1);

  const options: ViewItemOption[] = [
    {
      text: "FORWARD",
      onmousedown: () => {
        selectForward();
        closeViewOption();
      },
    },
    {
      text: `DELETE`,
      onmousedown: () => {
        eramHubConnection.emit("deleteGiMessages", [msg.id]);
        dispatch(delGIMessage(msg.id));
      },
    },
    { text: "PRINT", disabled: true },
  ];

  const chunkedMessage = chunkString(msg.message, rowMaxLen, true);
  const rowWidth = Math.max(...chunkedMessage.map((c) => c.length * fontDimension.width)) + 4;

  return (
    <container y={y} zIndex={_selected ? 1 : 0}>
      {!msg.acknowledged && (
        <graphics
          x={2}
          y={fontDimension.height}
          draw={(graphics) => {
            graphics.clear();
            graphics
              .moveTo(0, 0)
              .lineTo(fontDimension.width * 4, 0)
              .stroke({ color: tint, width: 1 });
          }}
        />
      )}
      <ViewItem
        text={chunkedMessage.join("\n")}
        centerText={false}
        textY={1}
        width={rowWidth}
        height={chunkedMessage.length * fontDimension.height + 2}
        fontSize={fontSize}
        tint={tint}
        fill={selected}
        fillColor={fillColor}
        onmousedown={(e) => {
          if (deleteMode) {
            deleteModeMouseDown(e);
            return;
          }
          if (!msg.acknowledged) {
            eramHubConnection.emit("acknowledgeGiMessage", msg.id);
          }
          openViewOption();
        }}
      >
        {_selected && <ViewItemOptionContainer xOffset={rowWidth} options={options} />}
      </ViewItem>
    </container>
  );
};

export const GI = () => {
  const dispatch = useRootDispatch();
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const unsortedMessages = useRootSelector(giMessagesSelector);
  const messages = unsortedMessages.toSorted((a, b) => b.createdAt - a.createdAt);
  const scrollProps = useScrollProps(view, messages.length, viewOptions.lines, {
    scrollDelta: viewOptions.lines,
  });
  const [deleteMode, setDeleteMode] = useState(false);
  const [messagesMarkedToDelete, setMessagesMarkedToDelete] = useState<GIMessageId[]>([]);
  const [selectedForwardMessageId, setSelectedForwardMessageId] = useState<Nullable<GIMessageId>>(null);

  const messageToForward = messages.find((m) => m.id === selectedForwardMessageId);

  const fontSize = viewOptions.font as EramFontSize;
  const fontDimension = eramFontDimensionMap[eramFontNameMap[fontSize]];

  const headerButtons: ViewHeaderProps["buttons"] = [
    {
      text: "DELETE",
      disabled: messages.length === 0,
      bgColor: deleteMode ? colorNameMap.brown : colorNameMap.cyan,
      onmousedown: () => {
        if (deleteMode) {
          setMessagesMarkedToDelete([]);
        }
        setDeleteMode(!deleteMode);
      },
    },
    {
      text: "PRINT",
      disabled: true,
      bgColor: colorNameMap.cyan,
    },
  ];

  const messagesToShow = messages.slice(scrollProps.scroll, scrollProps.scroll + viewOptions.lines);

  let height = 0;
  if (messagesToShow.length > 0) {
    height =
      6 +
      messagesToShow.reduce(
        (acc, cur) => acc + chunkString(cur.message, rowMaxLen, true).length * fontDimension.height + 4,
        0,
      );
  }

  const deleteModeMouseDown = (msgId: GIMessageId, event: FederatedPointerEvent) => {
    if (event.button === TBE) {
      eramHubConnection.emit("deleteGiMessages", [
        ...messagesMarkedToDelete.filter((id) => id !== msgId),
        msgId,
      ]);
      setDeleteMode(false);
      setMessagesMarkedToDelete([]);
    } else {
      setMessagesMarkedToDelete((prev) => {
        if (prev.includes(msgId)) {
          return prev.filter((id) => id !== msgId);
        } else {
          return [...prev, msgId];
        }
      });
    }
  };

  let y = 0;

  return (
    <>
      <ViewOptionContextProvider options={viewOptions}>
        <View width={width} height={height} view={view} headerButtons={headerButtons} optionMap={optionMap}>
          {messages.length > viewOptions.lines && (
            <ScrollBar y={2} x={2} height={height - 4} fontSize={2} {...scrollProps} />
          )}
          <container x={20} y={4} sortableChildren>
            {messagesToShow.map((msg) => {
              const rowY = y;
              y += chunkString(msg.message, rowMaxLen, true).length * fontDimension.height + 4;
              return (
                <GIRow
                  key={msg.id}
                  msg={msg}
                  deleteMode={deleteMode}
                  selectForward={() => {
                    dispatch(openView("GI_FORWARD_MENU"));
                    setSelectedForwardMessageId(msg.id);
                  }}
                  forceSelected={
                    messagesMarkedToDelete.includes(msg.id) || selectedForwardMessageId === msg.id
                  }
                  deleteModeMouseDown={(e) => deleteModeMouseDown(msg.id, e)}
                  y={rowY}
                />
              );
            })}
          </container>
        </View>
      </ViewOptionContextProvider>
      {messageToForward && (
        <GIForwardMenu
          key={messageToForward.id}
          msg={messageToForward}
          closeMenu={() => setSelectedForwardMessageId(null)}
        />
      )}
    </>
  );
};
