import React from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import {
  toolbarPositionSelector,
  openView,
  outageSelector,
  toggleView,
  viewSelector,
  viewsSelector,
  setToolbarPosition,
  showSatCommIndicatorSelector,
  setShowSatCommIndicator,
  giMessagesSelector,
} from "~redux/slices/edstSlice";
import { trialPlanQueueSelector } from "~redux/slices/trailPlanSlice";
import type { EdstView } from "types/edstView";
import { Time, timeWidth } from "components/utils/Time";
import {
  aclAckListSelector,
  aclSpaListSelector,
  aclUnackListSelector,
  depAckListSelector,
  depSpaListSelector,
  depUnackListSelector,
} from "~redux/slices/edstFlightplanSlice";
import { connectionSelector, getBitmapTextStyles, useFocused } from "@poscon/shared-frontend";
import type { ColorSource, FederatedEventHandler, FederatedPointerEvent, Graphics as PixiGraphics } from "pixi.js";
import { useWindowSize } from "usehooks-ts";
import { DOWN_ARROW, UP_ARROW } from "@poscon/shared-types";
import { font2Dimension, font2, headerRowHeight } from "~/utils/constants";
import { InteractiveContainer } from "./utils/InteractiveContainer";

const YELLOW = 0xa3a300;
const RED = 0x590000;

const disabledTint = 0x515151;
const defaultTint = 0xadadad;

const defaultButtonWidth = font2Dimension.width * 7 + 2;

type EdstBaseHeaderButtonProps = {
  x?: number;
  y?: number;
  onmousedown?: FederatedEventHandler<FederatedPointerEvent>;
  borderColor?: ColorSource;
  tint?: ColorSource;
  backgroundColor?: ColorSource;
  width?: number;
  disabled?: boolean;
  text: string;
};

type EdstHeaderButtonProps = EdstBaseHeaderButtonProps;

const noToggleViews = ["ACL", "DEP", "GPD", "WINDS", "PLANS_DISPLAY"];

const EdstHeaderButton = ({
  width = defaultButtonWidth,
  text,
  tint: _tint = defaultTint,
  backgroundColor = 0,
  disabled,
  borderColor = defaultTint,
  onmousedown,
  x,
}: EdstHeaderButtonProps) => {
  const ref = React.useRef<PixiGraphics>(null);
  const focused = useFocused(ref);

  const tint = disabled ? disabledTint : _tint;

  return (
    <container x={x}>
      <graphics
        ref={ref}
        eventMode={disabled ? "none" : "static"}
        draw={(graphics) => {
          graphics.clear();
          graphics.rect(0, 0, width, headerRowHeight).fill(backgroundColor)
            .stroke({ width: 1, color: disabled ? disabledTint : focused ? 0xf0f0f0 : borderColor });
        }}
        onMouseDown={onmousedown}
        zIndex={0}
      />
      <bitmapText
        zIndex={1}
        eventMode="none"
        text={text}
        roundPixels
        anchor={{ x: 0.5, y: 0 }}
        x={width ? Math.floor(width / 2) : 0}
        y={2}
        style={{ ...getBitmapTextStyles(font2), align: "center", fill: tint }}
      />
    </container>
  );
};

type EdstViewHeaderButtonProps = EdstBaseHeaderButtonProps & {
  view: EdstView;
};

const EdstViewHeaderButton = ({ view, ...props }: EdstViewHeaderButtonProps) => {
  const dispatch = useRootDispatch();
  const selected = useRootSelector((state) => viewSelector(state, view).open);

  const onmousedown = () => {
    if (noToggleViews.includes(view)) {
      dispatch(openView(view));
    } else {
      dispatch(toggleView(view));
    }
  };

  return (
    <EdstHeaderButton
      {...props}
      backgroundColor={props.backgroundColor ?? (selected ? 0x595959 : undefined)}
      onmousedown={onmousedown}
    />
  );
};

const width6 = font2Dimension.width * 6 + 2;
const EdstHeaderButton6 = (props: EdstViewHeaderButtonProps) => <EdstViewHeaderButton width={width6} {...props} />;

export const EdstToolbar = () => {
  const windowSize = useWindowSize();
  const dispatch = useRootDispatch();
  const planQueue = useRootSelector(trialPlanQueueSelector);
  const outages = useRootSelector(outageSelector);
  const views = useRootSelector(viewsSelector);
  const toolbarPosition = useRootSelector(toolbarPositionSelector);
  const showSatCommIndicator = useRootSelector(showSatCommIndicatorSelector);
  const connection = useRootSelector(connectionSelector);

  const aclLen = useRootSelector(
    (state) =>
      aclUnackListSelector(state).length + aclAckListSelector(state).length + aclSpaListSelector(state).length,
  );
  const depLen = useRootSelector(
    (state) =>
      depUnackListSelector(state).length + depAckListSelector(state).length + depSpaListSelector(state).length,
  );
  const sigLen = 0;
  const giLen = useRootSelector((state) => giMessagesSelector(state).filter((m) => !m.acknowledged).length);

  const containerX = font2Dimension.width * 6 + 10;

  const y = toolbarPosition === "top" ? 1 : windowSize.height - headerRowHeight - 2;
  const moreY = toolbarPosition === "top" ? headerRowHeight + 4 : -headerRowHeight - 4;

  return (
    <InteractiveContainer x={2} y={y} zIndex={100}>
      <container eventMode="static">
        <EdstHeaderButton
          text={toolbarPosition === "top" ? DOWN_ARROW : UP_ARROW}
          width={font2Dimension.width + 4}
          x={1}
          onmousedown={() => {
            dispatch(setToolbarPosition(toolbarPosition === "top" ? "bottom" : "top"));
          }}
        />
        <EdstViewHeaderButton
          view="MORE"
          text="MORE"
          width={font2Dimension.width * 5}
          x={font2Dimension.width + 8}
        />
        <container x={containerX}>
          <EdstViewHeaderButton view="ACL" text={`ACL\n${aclLen.toString().padStart(2, "0")}`} x={1} />
          <EdstViewHeaderButton
            view="DEP"
            text={`DEP\n${depLen.toString().padStart(2, "0")}`}
            x={1 + defaultButtonWidth + 4}
          />
          <EdstViewHeaderButton view="GPD" text="GPD" x={1 + (defaultButtonWidth + 4) * 2} />
          <EdstViewHeaderButton
            view="PLANS_DISPLAY"
            text="PLANS"
            disabled={planQueue.length === 0}
            x={1 + (defaultButtonWidth + 4) * 3}
          />
          <EdstViewHeaderButton view="WX_REPORT" text={"WX\nREPORT"} x={1 + (defaultButtonWidth + 4) * 4} />
          <EdstViewHeaderButton
            view="SIGMETS"
            borderColor={sigLen > 0 ? YELLOW : undefined}
            tint={sigLen > 0 ? YELLOW : undefined}
            text={`SIG\n${sigLen > 0 ? sigLen.toString().padStart(2, "0") : ""}`}
            x={1 + (defaultButtonWidth + 4) * 5}
          />
          <EdstViewHeaderButton
            view="GI"
            borderColor={giLen > 0 ? YELLOW : undefined}
            tint={giLen > 0 ? YELLOW : undefined}
            text={`GI\n${giLen > 0 ? giLen.toString().padStart(2, "0") : ""}`}
            x={1 + (defaultButtonWidth + 4) * 6}
          />
          <EdstViewHeaderButton view="UA" text="UA" disabled x={1 + (defaultButtonWidth + 4) * 7} />
          <EdstViewHeaderButton view="CPDLC_ADV" text={"CPDLC\nADV"} x={1 + (defaultButtonWidth + 4) * 8} />
          <EdstViewHeaderButton view="CPDLC_HIST" text={"CPDLC\nHIST"} x={1 + (defaultButtonWidth + 4) * 9} />
          <EdstViewHeaderButton view="CPDLC_MSGOUT" text={"CPDLC\nMSGOUT"} x={1 + (defaultButtonWidth + 4) * 10} />
          <EdstHeaderButton
            text={"KEEP\nALL"}
            x={1 + (defaultButtonWidth + 4) * 11}
            width={5 * font2Dimension.width + 2}
            disabled
          />
        </container>
        <container x={windowSize.width - 1} eventMode="static">
          <EdstViewHeaderButton
            view="STATUS"
            text="STATUS"
            x={-(width6 + 4) * 3 - (defaultButtonWidth + 4) * 2 - timeWidth}
          />
          <EdstViewHeaderButton
            backgroundColor={outages.filter((o) => !o.acknowledged).length > 0 ? RED : undefined}
            view="OUTAGE"
            text={`OUTAGE\n${connection?.sectorId?.split(".").at(-1) ?? ""}`}
            x={-defaultButtonWidth - (width6 + 4) * 3 - (timeWidth + 4)}
            disabled={outages.length === 0}
          />
          <Time x={-(width6 + 4) * 3 - timeWidth} />
          <EdstHeaderButton6 view="ADSB" text={"NON-\nADSB"} x={-width6 - (width6 + 4) * 2} disabled />
          <EdstHeaderButton
            text={"SAT\nCOMM"}
            x={-width6 - (width6 + 4)}
            width={width6}
            backgroundColor={showSatCommIndicator ? 0x595959 : undefined}
            onmousedown={() => {
              dispatch(setShowSatCommIndicator(!showSatCommIndicator));
            }}
          />
          <EdstHeaderButton6
            view="MSG"
            // backgroundColor={YELLOW}
            // borderColor={YELLOW}
            text={"MSG\nWAIT"}
            x={-width6}
            disabled
          />
        </container>
      </container>
      {views.MORE.open && (
        <container y={moreY} x={containerX}>
          <EdstViewHeaderButton view="WIND" text="WIND" x={1} />
          <EdstViewHeaderButton view="ALTIM_SET" text={"ALTIM\nSET"} x={1 + (defaultButtonWidth + 4)} />
          <EdstViewHeaderButton view="MESSAGE_COMPOSE_AREA" text="MCA" x={1 + (defaultButtonWidth + 4) * 2} />
          <EdstViewHeaderButton view="MESSAGE_RESPONSE_AREA" text="RA" x={1 + (defaultButtonWidth + 4) * 3} />
          <EdstViewHeaderButton view="FLIGHT_EVENT" text="FEL" disabled x={1 + (defaultButtonWidth + 4) * 4} />
        </container>
      )}
    </InteractiveContainer>
  );
};
