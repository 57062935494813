import type { Nullable } from "@poscon/shared-types";
import type { WindowPosition } from "@poscon/shared-frontend";
import type { EdstView } from "types/edstView";
import type { RootThunkAction } from "~redux/store";
import { openView, setViewPosition } from "~redux/slices/edstSlice";
import { Container } from "pixi.js";

const NO_REPOSITION_WINDOW: EdstView[] = ["ROUTE_MENU", "HOLD_MENU"];

export function openMenuThunk(
  edstView: EdstView,
  container: Nullable<Container>,
  plan = false,
  centerMenu = false
): RootThunkAction {
  return (dispatch) => {
    if (container && !NO_REPOSITION_WINDOW.includes(edstView)) {
      let menuPos: WindowPosition;
      const { x, y, width, height } = container.getBounds();
      switch (edstView) {
        case "ALTITUDE_MENU":
          menuPos = {
            x: x + (plan ? 0 : width),
            y: plan ? y : y - 76,
          };
          break;
        case "PREV_ROUTE_MENU":
          menuPos = {
            x,
            y: plan ? y : y - 2 * height,
          };
          break;
        case "SPEED_MENU":
          menuPos = {
            x: x + width,
            y: 200,
          };
          break;
        case "HEADING_MENU":
          menuPos = {
            x: x + width,
            y: 200,
          };
          break;
        case "TEMPLATE_MENU":
          menuPos = {
            x: 200,
            y: 200,
          };
          break;
        default:
          menuPos = {
            x,
            y,
          };
      }
      dispatch(setViewPosition({ view: edstView, pos: menuPos }));
    }
    dispatch(openView(edstView));
  };
}
