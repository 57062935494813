import React, { useRef } from "react";
import { AdvDeletePrompt, advDeleteView, selectedAdvisorySelector } from "@poscon/shared-frontend";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import type { Container as PixiContainer } from "pixi.js";
import { useDragging } from "hooks/useDragging";
import { layerZIndexMap } from "~/layerZIndexMap";
import { viewPositionSelector } from "~redux/slices/edstSlice";
import { useEventMode } from "../utils/InteractiveContainer";

const view = advDeleteView;

export const CpdlcAdvDeletePrompt = () => {
  const dispatch = useRootDispatch();
  const ref = useRef<PixiContainer | null>(null);
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const { startDrag } = useDragging(ref, view);
  const adv = useRootSelector(selectedAdvisorySelector);
  const eventMode = useEventMode();

  return adv ? (
    <AdvDeletePrompt
      ref={ref}
      pos={pos}
      startDrag={startDrag}
      zIndex={layerZIndexMap.prompts}
      adv={adv}
      eventMode={eventMode}
      dispatchCommand={(command) => dispatch(processEramMessage(command))}
    />
  ) : null;
};
