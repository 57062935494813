import React, { useCallback, useRef } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { aselSelector, setAsel, setViewPosition, viewPositionSelector } from "~redux/slices/edstSlice";
import { openMenuThunk } from "~redux/thunks/openMenuThunk";
import { useDragging } from "hooks/useDragging";
import { colorNameMap, getBitmapTextStyles, useCenterCursor } from "@poscon/shared-frontend";
import type { EdstView } from "types/edstView";
import { eramHubConnection } from "~/eramHubConnection";
import { edstFpSelector } from "~redux/slices/edstFlightplanSlice";
import type { Container as PixiContainer } from "pixi.js";
import type { FlightplanId } from "@poscon/shared-types";
import { MenuTitleBar } from "components/utils/MenuTitleBar";
import { defaultTint, disabledTint, font2, font2Dimension } from "~/utils/constants";
import { layerZIndexMap } from "~/layerZIndexMap";
import { TableField } from "components/utils/TableField";
import type { RootThunkAction } from "~redux/store";
import { isDepRowField } from "@poscon/shared-types/eram";
import { InteractiveContainer } from "../utils/InteractiveContainer";
import { EdstAircraftMenuProps } from "../menus/AircraftMenu";

type PlanOptionItem = {
  y: number;
  text: string;
  disabled?: boolean;
  action?: (fpId: FlightplanId) => RootThunkAction;
};

const depItems: PlanOptionItem[] = [];
const aclItems: PlanOptionItem[] = [
  {
    y: 0,
    text: "Altitude...",
    action: () => (dispatch, getState) => {
      const pos = viewPositionSelector(getState(), "PLAN_OPTIONS");
      dispatch(setViewPosition({ view: "ALTITUDE_MENU", pos }));
      dispatch(setAsel({ ...aselSelector(getState())!, menu: "ALTITUDE_MENU" }));
    },
  },
  {
    y: font2Dimension.height + 4,
    text: "Speed...",
    action: () => (dispatch, getState) => {
      const pos = viewPositionSelector(getState(), "PLAN_OPTIONS");
      dispatch(setViewPosition({ view: "SPEED_MENU", pos }));
      dispatch(setAsel({ ...aselSelector(getState())!, menu: "SPEED_MENU" }));
    },
  },
  {
    y: (font2Dimension.height + 4) * 2,
    text: "Route...",
    action: () => (dispatch, getState) => {
      const pos = viewPositionSelector(getState(), "PLAN_OPTIONS");
      dispatch(setViewPosition({ view: "ROUTE_MENU", pos }));
      dispatch(setAsel({ ...aselSelector(getState())!, menu: "ROUTE_MENU" }));
    },
  },
  { y: (font2Dimension.height + 4) * 3, text: "Previous Route", disabled: true },
  { y: (font2Dimension.height + 4) * 4, text: "Stop Probe...", disabled: true },
  { y: (font2Dimension.height + 4) * 5, text: "Trial Restrictions...", disabled: true },
  { y: (font2Dimension.height + 4) * 6, text: "Plans", disabled: true },
  { y: (font2Dimension.height + 4) * 7, text: "Stop Probe...", disabled: true },
  { y: (font2Dimension.height + 4) * 8, text: "Keep", disabled: true },
  {
    y: (font2Dimension.height + 4) * 9,
    text: "Delete",
    action: (fpId) => (dispatch) => {
      eramHubConnection.emit("deleteEdstFp", fpId);
      dispatch(setAsel(null));
    },
  },
];

const width = font2Dimension.width * 24;

type PlanOptionsProps = EdstAircraftMenuProps;
export const PlanOptions = ({ fp, asel }: PlanOptionsProps) => {
  const fpId = fp.id;
  const dispatch = useRootDispatch();
  const edstFp = useRootSelector((state) => edstFpSelector(state, fpId));

  const pos = useRootSelector((state) => viewPositionSelector(state, "PLAN_OPTIONS"));
  const ref = useRef<PixiContainer>(null);
  const dep = isDepRowField(asel.field);
  useCenterCursor(ref);
  const { startDrag } = useDragging(ref, "PLAN_OPTIONS", "mouseup");

  const openMenu = useCallback(
    (menu: EdstView) => {
      dispatch(openMenuThunk(menu, ref.current, false, true));
    },
    [dispatch],
  );

  const onKeepClick = () => {
    eramHubConnection.updateEdstFpData(fp.id, { keep: true });
  };

  const items = dep ? depItems : aclItems;
  const height = font2Dimension.height * 4 + (items.at(-1)?.y ?? 0);

  return (
    <InteractiveContainer ref={ref} x={pos.x} y={pos.y} zIndex={layerZIndexMap.prompts} sortableChildren>
      <MenuTitleBar
        title="PLAN OPTIONS MENU"
        width={width}
        onTitleMouseDown={startDrag}
        view="PLAN_OPTIONS"
      />
      <container y={font2Dimension.height + 3}>
        <graphics
          draw={(graphics) => {
            graphics.rect(0, 0, width, height).fill(0).stroke({ width: 1, color: colorNameMap.grey });
          }}
        />
        <bitmapText
          x={Math.floor(width / 2)}
          y={10}
          text={`${fp.cid}  ${fp.callsign}`}
          roundPixels
          anchor={{ x: 0.5, y: 0 }}
          style={{ ...getBitmapTextStyles(font2), fill: defaultTint }}
        />
        <container x={8} y={font2Dimension.height * 2 + 8}>
          {aclItems.map(({ y, text, disabled, action }) => (
            <TableField
              key={text}
              textX={2}
              y={y}
              width={width - 17}
              text={text}
              tint={disabled ? disabledTint : defaultTint}
              disabled={disabled}
              onmousedown={() => {
                if (action) {
                  dispatch(action(fpId));
                }
              }}
            />
          ))}
        </container>
      </container>
    </InteractiveContainer>
  );
};
