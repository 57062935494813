import React from "react";
import { EdstToolbar } from "components/EdstToolbar";
import { Acl } from "components/Acl";
import { Dep } from "components/Dep";
import { Status } from "components/Status";
import { Outage } from "components/Outage";
import { PlansDisplay } from "components/PlansDisplay";
import { MessageComposeArea } from "components/MessageComposeArea";
import { MessageResponseArea } from "components/MessageResponseArea";
import { TemplateMenu } from "components/TemplateMenu";
import { addSaaAltIdSelector, aselSelector, viewsSelector } from "~redux/slices/edstSlice";
import { useRootSelector } from "~redux/hooks";
import { ToolsMenu } from "components/tools/ToolsMenu";
import { AltimSet } from "components/views/AltimSet";
import { WxReport } from "components/views/WxReport";
import { EquipmentTemplateMenu } from "components/template/EquipmentTemplateMenu";
import { SigmetView } from "components/SigmetView";
import { Gpd } from "components/Gpd";
import { GpdMapOptions } from "components/gpd/GpdMapOptions";
import { GI } from "components/GIView";
import { AclSortMenu } from "components/acl/AclSortMenu";
import { DepSortMenu } from "components/dep/DepSortMenu";
import { CpdlcAdv } from "components/CpdlcAdv";
import { CpdlcHistory } from "components/CpdlcHistory";
import { CpdlcMsgOut } from "components/CpdlcMsgOut";
import { unsafeEntries } from "@poscon/shared-types";
import { DragContextProvider } from "contexts/dragContext";
import { CursorContextProvider } from "contexts/cursorContext";
import {
  advCreationTemplateView,
  advDeleteView,
  advFreetextTemplateView,
  advUplinkTemplateView,
  eligibilityPromptSelector,
  InopX,
  InputContextProvider,
  LockedUiBorder,
  selectedAdvViewSelector,
  useAutoSignin,
} from "@poscon/shared-frontend";
import { WindGridDisplay } from "components/WindGridDisplay";
import { WindAltMenu } from "components/wind/WindAltMenu";
import { AirspaceStatus } from "components/AirspaceStatus";
import { EligibilityOverridePrompt } from "components/prompts/EligibilityOverridePrompt";
import { CpdlcAdvCreationTemplate } from "components/CpdlcAdvCreationTemplate";
import { CpdlcAdvUplinkTemplate } from "components/CpdlcAdvUplinkTemplate";
import { CpdlcAdvDeletePrompt } from "components/CpdlcAdvDeletePrompt";
import { CpdlcAdvFreetextTemplate } from "components/CpdlcAdvFreetextTemplate";
import { AddAirspaceAltitude } from "components/prompts/AddAirspaceAltitude";
import { CCommonContextProvider } from "contexts/commonContext";
import { initializeConnection } from "./eramHubConnection";
import { RestrictionsView } from "./components/views/Restrictions";
import { AirportStreamFilterView } from "./components/views/AirportStreamFilter";
import { AselComponent } from "./components/AselComponent";

const edstComponentMap = {
  ACL: Acl,
  ACL_SORT_MENU: AclSortMenu,
  AIRSPACE_STATUS: AirspaceStatus,
  ALTIM_SET: AltimSet,
  CPDLC_ADV: CpdlcAdv,
  CPDLC_HIST: CpdlcHistory,
  CPDLC_MSGOUT: CpdlcMsgOut,
  DEP: Dep,
  DEP_SORT_MENU: DepSortMenu,
  EQUIPMENT_TEMPLATE_MENU: EquipmentTemplateMenu,
  RESTRICTIONS: RestrictionsView,
  AIRPORT_STREAM_FILTER: AirportStreamFilterView,
  GI,
  GPD: Gpd,
  GPD_MAP_OPTIONS_MENU: GpdMapOptions,
  MESSAGE_RESPONSE_AREA: MessageResponseArea,
  OUTAGE: Outage,
  PLANS_DISPLAY: PlansDisplay,
  SIGMETS: SigmetView,
  STATUS: Status,
  TEMPLATE_MENU: TemplateMenu,
  TOOLS_MENU: ToolsMenu,
  WIND: WindGridDisplay,
  WIND_ALT_MENU: WindAltMenu,
  WX_REPORT: WxReport,
} as const;

const cpdlcAdvViewMap = {
  [advCreationTemplateView]: CpdlcAdvCreationTemplate,
  [advUplinkTemplateView]: CpdlcAdvUplinkTemplate,
  [advDeleteView]: CpdlcAdvDeletePrompt,
  [advFreetextTemplateView]: CpdlcAdvFreetextTemplate,
};

export const Edst = () => {
  useAutoSignin(initializeConnection);
  const views = useRootSelector(viewsSelector);
  const asel = useRootSelector(aselSelector);
  const eligibilityPrompt = useRootSelector(eligibilityPromptSelector);
  const selectedAdvView = useRootSelector(selectedAdvViewSelector);
  const addSaaAltId = useRootSelector(addSaaAltIdSelector);

  const CpdlcAdvComponent = selectedAdvView ? cpdlcAdvViewMap[selectedAdvView.option] : null;

  return (
    <>
      <InopX />
      <CCommonContextProvider>
        <DragContextProvider>
          <CursorContextProvider>
            <InputContextProvider>
              <LockedUiBorder />
              <EdstToolbar />
              <MessageComposeArea />
              {eligibilityPrompt && (
                <EligibilityOverridePrompt
                  key={eligibilityPrompt.callsign + eligibilityPrompt.title}
                  prompt={eligibilityPrompt}
                />
              )}
              {CpdlcAdvComponent && <CpdlcAdvComponent key={selectedAdvView?.name} />}
              {unsafeEntries(edstComponentMap).map(
                ([edstView, Component]) => views[edstView].open && <Component key={edstView} />,
              )}
              {addSaaAltId && <AddAirspaceAltitude key={addSaaAltId} saaId={addSaaAltId} />}
              {asel && <AselComponent key={asel.fpId} asel={asel} fpId={asel.fpId} />}
            </InputContextProvider>
          </CursorContextProvider>
        </DragContextProvider>
      </CCommonContextProvider>
    </>
  );
};
