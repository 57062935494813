import type { RootState, RootStore } from "~redux/store";
import {
  updateGIMessage,
  edstSlice,
  setDPosConfig,
  setEdstState,
  setGIMessages,
  setEdsmConfig,
} from "~redux/slices/edstSlice";
import type { EramHubConnection } from "@poscon/shared-frontend";
import {
  viewMenuFieldsSlice,
  viewOptionSliceName,
  setAltimeterList,
  setMetarList,
  baseEramHubConnection,
  isRestrictedUiAction,
  messagingSlice,
  uiSubscribedSelector,
} from "@poscon/shared-frontend";
import {
  deleteEdstFp,
  setAclAckList,
  setAclSpaList,
  setAclUnackList,
  setDepAckList,
  setDepSpaList,
  setDepUnackList,
  setEdstFlightplans,
  updateEdstFlightplan,
} from "~redux/slices/edstFlightplanSlice";
import { aclSlice, setAclPostingMode, setAclSort, setAclState } from "~redux/slices/aclSlice";
import { depSlice, setDepPostingMode, setDepSort, setDepState } from "~redux/slices/depSlice";
import { env } from "~/env";
import { setTrialPlanQueue } from "~redux/slices/trailPlanSlice";
import type { DefaultEventsMap } from "@socket.io/component-emitter";
import { setViewOptionState } from "~redux/slices/viewOptionSlice";
import { listenerMiddleware } from "~redux/listenerMiddleware";
import { gpdSlice, setGpdState } from "~redux/slices/gpdSlice";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { isAction } from "@reduxjs/toolkit";
import { InflatedEdstClientEventMap, ArtccId } from "@poscon/shared-types";
import { ControllerSectorId, PosconRole } from "@poscon/shared-types/poscon";

const syncedUiSlices = [
  edstSlice.name,
  viewOptionSliceName,
  aclSlice.name,
  depSlice.name,
  gpdSlice.name,
] as const;
type SyncedUiSlice = (typeof syncedUiSlices)[number];

let store: RootStore;
export const ERAM_SERVER_URL = env.VITE_ERAM_SERVER_URL;
export const eramHubConnection = baseEramHubConnection as EramHubConnection<
  InflatedEdstClientEventMap & DefaultEventsMap
>;
eramHubConnection.url = ERAM_SERVER_URL;

export const injectStore = async (s: RootStore) => {
  store = s;
  eramHubConnection.dispatch = store.dispatch;
  eramHubConnection.getState = () => {
    const state = store.getState();
    return Object.fromEntries(syncedUiSlices.map((slice) => [slice, state[slice]]));
  };
  eramHubConnection.registerEventHandler("receiveEdstUiState", (_state) => {
    const state = _state as Pick<RootState, SyncedUiSlice>;
    store.dispatch(setViewOptionState(state.viewOptions));
    store.dispatch(setEdstState(state.edst));
    store.dispatch(setAclState(state.acl));
    store.dispatch(setDepState(state.dep));
    store.dispatch(setGpdState(state.gpd));
  });
  eramHubConnection.registerEventHandler("receiveEdstUiAction", (action) => {
    if (isAction(action)) {
      store.dispatch({ ...action, meta: { forwarded: true } });
    }
  });
  eramHubConnection.registerEventHandler("receiveAltimeterList", (altimeterList) => {
    store.dispatch(setAltimeterList(altimeterList));
  });
  eramHubConnection.registerEventHandler("receiveMetarList", (metarList) => {
    store.dispatch(setMetarList(metarList));
  });
  eramHubConnection.registerEventHandler("receiveAclPostingMode", (postingMode) => {
    store.dispatch(setAclPostingMode(postingMode));
  });
  eramHubConnection.registerEventHandler("receiveDepPostingMode", (postingMode) => {
    store.dispatch(setDepPostingMode(postingMode));
  });
  eramHubConnection.registerEventHandler("receiveAclSortMethod", (sortMethod, sortSector) => {
    store.dispatch(setAclSort({ selectedOption: sortMethod, sector: sortSector }));
  });
  eramHubConnection.registerEventHandler("receiveDepSortMethod", (sortMethod) => {
    store.dispatch(setDepSort(sortMethod));
  });
  eramHubConnection.registerEventHandler("receiveAclLists", (unackList, ackList, spaList) => {
    store.dispatch(setAclUnackList(unackList));
    store.dispatch(setAclAckList(ackList));
    store.dispatch(setAclSpaList(spaList));
  });
  eramHubConnection.registerEventHandler("receiveDepLists", (unackList, ackList, spaList) => {
    store.dispatch(setDepUnackList(unackList));
    store.dispatch(setDepAckList(ackList));
    store.dispatch(setDepSpaList(spaList));
  });
  eramHubConnection.registerEventHandler("receiveTrialPlans", (trialPlans) => {
    store.dispatch(setTrialPlanQueue(trialPlans));
  });
  eramHubConnection.registerEventHandler("receiveEdstFpMap", (fpMap) => {
    store.dispatch(setEdstFlightplans(fpMap));
  });
  eramHubConnection.registerEventHandler("updateEdstFp", (fpId, data) => {
    store.dispatch(updateEdstFlightplan({ fpId, data }));
  });
  eramHubConnection.registerEventHandler("deleteEdstFp", (fpId) => {
    store.dispatch(deleteEdstFp(fpId));
  });
  eramHubConnection.registerEventHandler("receiveGiMessages", (messages) => {
    store.dispatch(setGIMessages(messages));
  });
  eramHubConnection.registerEventHandler("receiveGiMessage", (msg) => {
    store.dispatch(updateGIMessage(msg));
  });
};

export async function initializeConnection(
  artccId: ArtccId,
  sectorId: ControllerSectorId | null = null,
  role: PosconRole = "Data",
) {
  await eramHubConnection.initialize(artccId, "EDST", ERAM_SERVER_URL, sectorId, role);
  eramHubConnection.getDposConfig().then((dPosConfig) => {
    store.dispatch(setDPosConfig(dPosConfig));
  });
  eramHubConnection.getEDSMConfig().then((config) => {
    store.dispatch(
      setEdsmConfig({ areas: config.areas, defaultCombinedSectors: config.defaultCombinedSectors }),
    );
  });
}

export const uiActionReducerNames: string[] = [
  viewOptionSliceName,
  edstSlice.name,
  messagingSlice.name,
  aclSlice.name,
  depSlice.name,
  gpdSlice.name,
  viewMenuFieldsSlice.name,
];

listenerMiddleware.startListening({
  predicate: (action, currentState) =>
    uiSubscribedSelector(currentState as any) && isRestrictedUiAction(action, uiActionReducerNames),
  effect: (action) => {
    eramHubConnection.emit("uiAction", action);
  },
});

if (window.__TAURI__) {
  const webviewWindow = WebviewWindow.getCurrent();
  await webviewWindow.listen("poscon:close", async () => {
    if (eramHubConnection.isActive) {
      try {
        await eramHubConnection.signout();
      } catch (err) {
        console.error(err);
      }
    }
    void webviewWindow.close();
  });
}
