import React, { useState } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { aclSortDataSelector } from "~redux/slices/aclSlice";
import { SortMenu, sortMenuWidth } from "components/SortMenu";
import type { AclSortMethod } from "@poscon/shared-types/eram";
import { font2Dimension } from "~/utils/constants";
import { SortMenuItem } from "components/utils/SortMenuItem";
import { closeView } from "~redux/slices/edstSlice";
import { AclSortMethodValues } from "@poscon/shared-types/eram";
import { eramHubConnection } from "~/eramHubConnection";
import { colorNameMap } from "@poscon/shared-frontend";

const sortOptionList: { option: AclSortMethod; text: string; disabled: boolean }[] = [
  { option: "ACL_ACID_SORT_METHOD", text: AclSortMethodValues.ACL_ACID_SORT_METHOD, disabled: false },
  {
    option: "ACL_BOUNDARY_TIME_SORT_METHOD",
    text: AclSortMethodValues.ACL_BOUNDARY_TIME_SORT_METHOD,
    disabled: false,
  },
  {
    option: "ACL_CONFLICT_STATUS_SORT_METHOD",
    text: AclSortMethodValues.ACL_CONFLICT_STATUS_SORT_METHOD,
    disabled: true,
  },
  {
    option: "ACL_CONFLICT_TIME_SORT_METHOD",
    text: AclSortMethodValues.ACL_CONFLICT_TIME_SORT_METHOD,
    disabled: true,
  },
  {
    option: "ACL_DESTINATION_SORT_METHOD",
    text: AclSortMethodValues.ACL_DESTINATION_SORT_METHOD,
    disabled: false,
  },
  {
    option: "ACL_SECTOR_BY_SECTOR_SORT_METHOD",
    text: AclSortMethodValues.ACL_SECTOR_BY_SECTOR_SORT_METHOD,
    disabled: false,
  },
];

export const AclSortMenu = () => {
  const dispatch = useRootDispatch();
  const aclSortData = useRootSelector(aclSortDataSelector);
  const [sector, setSector] = useState(aclSortData.sector);
  const [selectedOption, setSelectedOption] = useState(aclSortData.selectedOption);

  const onSubmit = () => {
    eramHubConnection.emit("setAclSortMethod", selectedOption, sector);
    dispatch(closeView("ACL_SORT_MENU"));
  };

  return (
    <SortMenu menu="ACL_SORT_MENU" onSubmit={onSubmit} height={font2Dimension.height * 11}>
      <graphics
        draw={(graphics) => {
          graphics.clear();
          graphics
            .moveTo(0, font2Dimension.height * 2 - 4)
            .lineTo(sortMenuWidth, font2Dimension.height * 2 - 4)
            .stroke({ width: 1, color: colorNameMap.grey });
        }}
      />
      <SortMenuItem
        x={font2Dimension.width}
        y={2}
        text="Sector/Non-Sector"
        selected={sector}
        indicator="rectangle"
        onmousedown={() => setSector((prev) => !prev)}
      />
      <container x={font2Dimension.width} y={font2Dimension.height * 2}>
        {sortOptionList.map(({ option, text, disabled }, i) => (
          <SortMenuItem
            key={option}
            text={text}
            disabled={disabled}
            selected={selectedOption === option}
            onmousedown={() => setSelectedOption(option)}
            y={i * (font2Dimension.height + 7)}
          />
        ))}
      </container>
    </SortMenu>
  );
};
