import type { EdstView } from "types/edstView";
import {
  advCreationTemplateView,
  advDeleteView,
  advFreetextTemplateView,
  advUplinkTemplateView,
  cpdlcAdvView,
  cpdlcHistView,
  cpdlcMsgOutView,
} from "@poscon/shared-frontend";

export const viewTitleMap: Partial<
  Record<
    EdstView,
    {
      title: string;
      optionTitle: string;
    }
  >
> = {
  [advFreetextTemplateView]: { optionTitle: "", title: "FREETEXT TEMPLATE" },
  [advDeleteView]: { optionTitle: "", title: "DELETE ADVISORY" },
  [advCreationTemplateView]: {
    optionTitle: "",
    title: "ADVISORY CREATION/EDIT TEMPLATE",
  },
  [cpdlcAdvView]: {
    title: "ADVISORIES",
    optionTitle: "ADV",
  },
  [cpdlcHistView]: {
    title: "HISTORY",
    optionTitle: "HIST",
  },
  [cpdlcMsgOutView]: {
    title: "MESSAGE OUT",
    optionTitle: "MSG OUT",
  },
  [advUplinkTemplateView]: { optionTitle: "", title: "UPLINK TEMPLATE" },
  ALTIM_SET: {
    title: "ALTIM SET",
    optionTitle: "AS",
  },
  GI: { optionTitle: "GI", title: "GENERAL INFORMATION MESSAGES" },
  MESSAGE_COMPOSE_AREA: { optionTitle: "MCA", title: "" },
  MESSAGE_RESPONSE_AREA: { optionTitle: "RA", title: "" },
  OUTAGE: {
    title: "OUTAGE",
    optionTitle: "OUTAGE",
  },
  SIGMETS: { optionTitle: "SIG", title: "" },
  STATUS: {
    title: "STATUS",
    optionTitle: "STATUS",
  },
  UA: {
    title: "UA",
    optionTitle: "UA",
  },
  WX_REPORT: {
    title: "WX",
    optionTitle: "WX",
  },
};
