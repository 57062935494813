import React from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { depPostingModeSelector, depSortOptionSelector } from "~redux/slices/depSlice";
import { closeView, depAselSelector, setAsel } from "~redux/slices/edstSlice";
import { DepSortMethodValues, stringToParsedTokenArray, isDepRowField } from "@poscon/shared-types/eram";
import type { HeaderComponentProps } from "components/utils/FullscreenView";
import { EdstButton } from "components/utils/EdstButton";
import { titleBarHeight, ViewTitleBar } from "components/ViewTitleBar";
import { openViewThunk } from "~redux/thunks/openViewThunk";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import { defaultTint, font2, font2Dimension } from "~/utils/constants";
import { eramHubConnection } from "~/eramHubConnection";
import { getBitmapTextStyles, useInputProps } from "@poscon/shared-frontend";
import { EdstInput } from "components/utils/EdstInput";
import { aclHeaderHeight } from "components/acl/AclHeader";
import { Container } from "pixi.js";

export const depHeaderHeight = titleBarHeight + font2Dimension.height * 3 + 11;

/**
 * DEP title bar and header row with add/find input field
 */
export const DepHeader = ({ focused, toggleFullscreen, startDrag, width }: HeaderComponentProps) => {
  const asel = useRootSelector(depAselSelector);
  const selectedSortOption = useRootSelector(depSortOptionSelector);
  const postingMode = useRootSelector(depPostingModeSelector);
  const dispatch = useRootDispatch();
  const addFindInputProps = useInputProps(
    `DEP/header/addFind`,
    "",
    (searchStr) => {
      dispatch(processEramMessage(stringToParsedTokenArray(`UU D ${searchStr}`)));
    },
    {
      maxLength: 7,
      focusOnMount: false,
    },
  );

  return (
    <container>
      <ViewTitleBar
        width={width}
        focused={focused}
        toggleFullscreen={toggleFullscreen}
        startDrag={startDrag}
        closeView={() => {
          if (isDepRowField(asel?.field)) {
            setAsel(null);
          }
          dispatch(closeView("DEP"));
        }}
        text={[
          "Departure List",
          `${DepSortMethodValues[selectedSortOption]}`,
          `${postingMode === "MANUAL" ? "Manual" : "Automatic"}`,
        ]}
      />
      <container y={titleBarHeight + 3}>
        <graphics
          draw={(graphics) => {
            graphics.clear();
            graphics
              .moveTo(1, font2Dimension.height + 8)
              .lineTo(width, font2Dimension.height + 8)
              .moveTo(1, aclHeaderHeight - (titleBarHeight + 3))
              .lineTo(width, aclHeaderHeight - (titleBarHeight + 3))
              .stroke({ width: 1, color: 0xadadad });
          }}
        />
        <EdstButton
          x={4}
          disabled={asel === null}
          onmousedown={(e) => {
            dispatch(openViewThunk("PLAN_OPTIONS", e.target as Container));
          }}
          text="Plan Options..."
        />
        <EdstButton
          x={4 + font2Dimension.width * 15 + 8 * 2}
          text="Sort..."
          onmousedown={(e) => {
            dispatch(openViewThunk("DEP_SORT_MENU", e.target as Container));
          }}
        />
        <EdstButton
          x={4 + font2Dimension.width * 22 + 8 * 4}
          text="Posting Mode"
          onmousedown={() => eramHubConnection.emit("toggleDepPostingMode")}
        />
        <EdstButton
          x={4 + font2Dimension.width * 34 + 8 * 6}
          text="Template..."
          onmousedown={(e) => {
            dispatch(openViewThunk("TEMPLATE_MENU", e.target as Container));
          }}
        />
      </container>
      <container y={depHeaderHeight - (font2Dimension.height + 13)}>
        <bitmapText
          text="ADD/FIND"
          y={4}
          x={font2Dimension.width}
          style={{ ...getBitmapTextStyles(font2), fill: defaultTint }}
        />
        <EdstInput {...addFindInputProps} x={font2Dimension.width * 11} width={font2Dimension.width * 9} />
      </container>
    </container>
  );
};
