import React, { useRef, useState } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { viewSelector } from "~redux/slices/edstSlice";
import { useDragging } from "hooks/useDragging";
import {
  colorNameMap,
  eramFontNameMap,
  eramTextDimensionMap,
  useCenterCursor,
} from "@poscon/shared-frontend";
import type { Container as PixiContainer } from "pixi.js";
import { useZIndex } from "hooks/useZIndex";
import { MenuTitleBar } from "components/utils/MenuTitleBar";
import { TableField } from "components/utils/TableField";
import { openViewThunk } from "~redux/thunks/openViewThunk";

const fontName = eramFontNameMap[2];
const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[2];

const width = 36 * fontWidth;

export const ToolsMenu = () => {
  const dispatch = useRootDispatch();
  const viewProps = useRootSelector((state) => viewSelector(state, "TOOLS_MENU"));
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
  const ref = useRef<PixiContainer>(null);
  const zIndex = useZIndex("TOOLS_MENU", ref);
  useCenterCursor(ref);
  const { startDrag } = useDragging(ref, "TOOLS_MENU", "mouseup");

  return (
    <container
      ref={ref}
      x={viewProps.position.x}
      y={viewProps.position.y}
      zIndex={zIndex}
      eventMode="static"
      sortableChildren
    >
      <MenuTitleBar title="TOOLS MENU" width={width} onTitleMouseDown={startDrag} view="TOOLS_MENU" />
      <container y={fontHeight + 2} sortableChildren>
        <graphics
          draw={(graphics) => {
            graphics.clear();
            graphics
              .rect(0, 0, width, (fontHeight + 4) * 4 + 6)
              .fill(0)
              .stroke({ width: 1, color: colorNameMap.grey });
          }}
        />
        <TableField
          textX={2}
          x={4}
          y={4}
          text="Airspace Status..."
          width={width - 8}
          onmousedown={() => dispatch(openViewThunk("AIRSPACE_STATUS"))}
        />
        <TableField
          textX={2}
          x={4}
          y={4 + fontHeight + 4}
          text="Airport Stream Filter Status..."
          width={width - 8}
          onmousedown={() => dispatch(openViewThunk("AIRPORT_STREAM_FILTER"))}
        />
        <TableField textX={2} x={4} y={4 + (fontHeight + 4) * 2} text="Options..." width={width - 8} />
        <TableField
          textX={2}
          x={4}
          y={4 + (fontHeight + 4) * 3}
          text="Restrictions..."
          width={width - 8}
          onmousedown={() => dispatch(openViewThunk("RESTRICTIONS"))}
        />
      </container>
    </container>
  );
};

const ToolsOptionsMenu = () => {
  // TODO: implement
  return null;
};
