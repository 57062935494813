import {
  colorNameMap,
  eramCpdlcFontNameMap,
  eramFontDimensionMap,
  eramFontNameMap,
} from "@poscon/shared-frontend";

export const SPA_INDICATOR = "^";
export const COMPLETED_CHECKMARK_SYMBOL = "\u2713";

// 2 minutes
export const REMOVAL_TIMEOUT = 120e3;

export const font2 = eramFontNameMap[2];
export const font2Dimension = eramFontDimensionMap[font2];
export const cpdlcFont2 = eramCpdlcFontNameMap[2];
export const cpdlcFont2Dimension = eramFontDimensionMap[cpdlcFont2];
export const headerRowHeight = font2Dimension.height * 2 + 2;
export const viewTitleFillColor = colorNameMap.grey.map((c) => c * 0.7);
export const topLeftBorderColor = 0x888888;
export const bottomRightBorderColor = 0x575757;
export const tableSeparatorColor = 0x252525;
export const postingAreaSeparatorColor = 0xe3cfb4;
export const alertRed = 0xad0000;
export const alertYellow = 0xadad00;
export const alertOrange = 0xd48855;
export const defaultTint = 0xadadad;
export const unpairedFpTint = 0x695547;
export const disabledTint = 0x575757;
