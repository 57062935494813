import type { Nullable } from "@poscon/shared-types";
import type { EdstView } from "types/edstView";
import type { RootThunkAction } from "~redux/store";
import { openView, setViewPosition } from "~redux/slices/edstSlice";
import type { Container } from "pixi.js";

export function openViewThunk(window: EdstView, element?: Nullable<Container>): RootThunkAction {
  return (dispatch) => {
    if (element) {
      const { x, y, height } = element.getBounds();
      const windowPos = {
        x: Math.floor(x) + 1,
        y: Math.floor(y + height) + 1,
      };
      dispatch(setViewPosition({ view: window, pos: windowPos }));
    }
    dispatch(openView(window));
  };
}
