import { viewTitleFillColor } from "~/utils/constants";
import { baseBorderColor, colorNameMap, eramTextDimensionMap, ViewItem } from "@poscon/shared-frontend";
import { closeAselMenu, closeView } from "~redux/slices/edstSlice";
import React from "react";
import type { EdstView } from "types/edstView";
import type { FederatedEventHandler } from "pixi.js";
import { useRootDispatch } from "~redux/hooks";
import { isAselMenu } from "types/edstView";

const { width: fontWidth, height: fontHeight } = eramTextDimensionMap[2];

type MenuTitleBarProps = {
  title: string;
  view: EdstView;
  width: number;
  onTitleMouseDown: FederatedEventHandler;
  closeMenu?: () => void;
};
export const MenuTitleBar = ({ title, width, onTitleMouseDown, closeMenu, view }: MenuTitleBarProps) => {
  const dispatch = useRootDispatch();
  const xX = width - fontHeight;
  const labelWidth = width - fontHeight;

  return (
    <>
      <ViewItem
        text={title}
        width={labelWidth}
        onmousedown={onTitleMouseDown}
        fillColor={viewTitleFillColor}
        fill
        tint={colorNameMap.white}
        borderColor={baseBorderColor}
      />
      <ViewItem
        text="X"
        x={xX}
        width={fontHeight}
        onmousedown={() => {
          if (isAselMenu(view)) {
            dispatch(closeAselMenu());
          }
          closeMenu?.();
          dispatch(closeView(view));
        }}
        fillColor={viewTitleFillColor}
        fill
        tint={colorNameMap.white}
        borderColor={baseBorderColor}
      />
    </>
  );
};
