import type { Container as PixiContainer, Graphics as PixiGraphics } from "pixi.js";
import React, { useRef } from "react";
import { getBitmapTextStyles, useFocused } from "@poscon/shared-frontend";
import { sortMenuWidth } from "components/SortMenu";
import { bottomRightBorderColor, font2, font2Dimension, topLeftBorderColor } from "~/utils/constants";

type SortOptionProps = {
  text: string;
  disabled?: boolean;
  indicator?: "diamond" | "rectangle";
  selected: boolean;
  x?: number;
  y: number;
} & Pick<PixiContainer, "onmousedown">;
export const SortMenuItem = ({
  text,
  disabled,
  selected,
  onmousedown,
  y,
  x = 0,
  indicator = "diamond",
}: SortOptionProps) => {
  const ref = useRef<PixiGraphics>(null);
  const focused = useFocused(ref);

  const tint = disabled ? 0x575757 : 0xadadad;

  return (
    <container x={x} y={y} eventMode={disabled ? "none" : "passive"}>
      <graphics
        zIndex={0}
        ref={ref}
        eventMode="static"
        onMouseDown={onmousedown}
        draw={(graphics) => {
          graphics.clear();
          graphics
            .rect(0, 0, sortMenuWidth - font2Dimension.width * 2, font2Dimension.height + 4)
            .fill(0)
            .stroke({ width: 1, color: focused ? 0xffffff : 0x000000 });
        }}
      />
      <graphics
        y={Math.floor(font2Dimension.height / 2) + 3}
        x={11}
        zIndex={1}
        eventMode="none"
        angle={indicator === "diamond" ? 45 : 0}
        draw={(graphics) => {
          graphics.clear();
          graphics
            .rect(-5, -5, 10, 10)
            .fill(selected ? 0xadadad : 0x000000)
            .stroke({ width: 2, color: selected ? bottomRightBorderColor : topLeftBorderColor });
          graphics
            .moveTo(5, -5)
            .lineTo(5, 5)
            .lineTo(-5, 5)
            .stroke({ width: 2, color: selected ? topLeftBorderColor : bottomRightBorderColor });
        }}
      />
      <bitmapText
        zIndex={1}
        text={text.toUpperCase()}
        x={font2Dimension.width + 12}
        y={3}
        eventMode="none"
        style={{ ...getBitmapTextStyles(font2), fill: tint }}
      />
    </container>
  );
};
