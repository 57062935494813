import type React from "react";
import { useRef, useState } from "react";
import { useRootDispatch } from "~redux/hooks";
import { LogicalPosition } from "@tauri-apps/api/window";
import type { EdstView } from "types/edstView";
import { setIsFullscreen, setViewDimension, setViewPosition } from "~redux/slices/edstSlice";
import type { Container, FederatedPointerEvent } from "pixi.js";
import { Rectangle } from "pixi.js";
import {
  clipCursorToWindow,
  setSelectedViewOption,
  TBE,
  useOnUnmount,
  usePixiMouseEventListener,
  useStableCallback,
  useUiIsLocked,
} from "@poscon/shared-frontend";
import { useAnyDragging, useDragContext } from "contexts/dragContext";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import type { Coordinate } from "@poscon/shared-types";
import { useFullscreen } from "hooks/useFullscreen";

type StopDragOn = "mousedown" | "mouseup";

/**
 * hook to provide startDrag/endDrag functions with a previewStyle to render the previewWindow
 * @param ref ref to a Pixi element or a Rectangle object representing the absolute bounds of the object
 * @param view
 * @param stopDragOn whether to listen for stopDrag onmousedown or onMouseUp
 * @param repositionCursor
 * @returns
 */
export const useDragging = (
  ref: React.RefObject<Container | Rectangle | null>,
  view: EdstView,
  stopDragOn: StopDragOn = "mousedown",
  repositionCursor = stopDragOn === "mousedown",
) => {
  const dispatch = useRootDispatch();
  const {
    setDraggingOutlineVisible,
    setDraggingOutlinePositionAndDimension,
    getDraggingOutlinePositionAndDimension,
    draggingHandler,
  } = useDragContext();
  // on middleClick I always want to stop drag onmouseup
  const [currentStopDragOn, setCurrentStopDragOn] = useState(stopDragOn);
  const { anyDragging, setAnyDragging } = useAnyDragging();
  const isDraggingRef = useRef(false);
  const draggingHandlerRef = useRef<null | ((event: MouseEvent) => void)>(null);
  const { isFullscreen } = useFullscreen(view);
  const uiIsLocked = useUiIsLocked();

  const startDrag = useStableCallback(async (event: FederatedPointerEvent) => {
    if (
      !uiIsLocked &&
      !isDraggingRef.current &&
      !anyDragging &&
      ref.current &&
      (event.button === 0 || event.button === 1)
    ) {
      event.stopImmediatePropagation();
      dispatch(setSelectedViewOption(null));
      if (event.button === TBE) {
        setCurrentStopDragOn("mousedown");
      }
      const pos = ref.current instanceof Rectangle ? ref.current : ref.current.getGlobalPosition();
      const previewPos = { x: pos.x, y: pos.y };
      const width = ref.current.width - 1;
      const height = ref.current.height - 1;
      try {
        if (window.__TAURI__) {
          if (repositionCursor) {
            await WebviewWindow.getCurrent().setCursorPosition(
              new LogicalPosition(previewPos.x, previewPos.y),
            );
            await clipCursorToWindow(width - 1, height);
          } else {
            await clipCursorToWindow(0, 0);
          }
        }
      } catch (e) {
        console.error(e);
      }
      setDraggingOutlinePositionAndDimension(previewPos.x, previewPos.y, width, height);
      setDraggingOutlineVisible(true);
      isDraggingRef.current = true;
      setAnyDragging(true);
      const dragOffset: Coordinate = [0, 0];
      if (!repositionCursor) {
        dragOffset[0] = previewPos.x - event.global.x;
        dragOffset[1] = previewPos.y - event.global.y;
      }
      draggingHandlerRef.current = (event) => draggingHandler(event, dragOffset);
      window.addEventListener("mousemove", draggingHandlerRef.current, {
        passive: true,
        capture: true,
      });
    }
  });

  const stopDrag = useStableCallback(() => {
    if (draggingHandlerRef.current) {
      window.removeEventListener("mousemove", draggingHandlerRef.current, { capture: true });
      draggingHandlerRef.current = null;
    }
    if (isDraggingRef.current) {
      if (window.__TAURI__) {
        void WebviewWindow.getCurrent().setCursorGrab(false).catch();
      }
      const { x, y, width, height } = getDraggingOutlinePositionAndDimension();
      const newPos = { x, y };
      if (isFullscreen) {
        dispatch(setIsFullscreen({ view, value: false }));
        dispatch(setViewDimension({ view, dim: { width, height } }));
      }
      dispatch(
        setViewPosition({
          view,
          pos: newPos,
        }),
      );
      setAnyDragging(false);
      isDraggingRef.current = false;
      setDraggingOutlineVisible(false);
    }
  });

  usePixiMouseEventListener(
    (event) => {
      if (isDraggingRef.current) {
        event.stopImmediatePropagation();
        setCurrentStopDragOn(stopDragOn);
        void stopDrag();
      }
    },
    undefined,
    true,
    currentStopDragOn,
  );

  useOnUnmount(stopDrag);

  return { startDrag, anyDragging };
};
