import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { useDragging } from "hooks/useDragging";
import { ViewMenu } from "components/utils/ViewMenu";
import { useBoolean, useEventListener, useWindowSize } from "usehooks-ts";
import { LogicalPosition } from "@tauri-apps/api/window";
import {
  openView,
  pushZStack,
  viewPositionSelector,
  viewSelector,
  zStackSelector,
} from "~redux/slices/edstSlice";
import { chunkEramMessage, getEramMessageElements, stringToTokenArray } from "@poscon/shared-types/eram";
import type { EramMessageElement } from "@poscon/shared-types/eram";
import { useZIndex } from "hooks/useZIndex";
import { brightOption, counterOption, fontOption, viewOptionSelector } from "~redux/slices/viewOptionSlice";
import type { FederatedPointerEvent, Container as PixiContainer } from "pixi.js";
import { processEramMessage } from "~redux/thunks/processEramMessage";
import { ViewOptionContextProvider } from "contexts/viewOptionContext";
import type { EramFontSize, InsertCommandEvent, RecallCommandEvent } from "@poscon/shared-frontend";
import {
  baseBorderColor,
  colorNameMap,
  computeColor,
  connectionSelector,
  dispatchInsertCommandEvent,
  eramFontDimensionMap,
  eramFontNameMap,
  errorTone,
  getBitmapTextStyles,
  isProcessingCommandSelector,
  mcaFeedbackSelector,
  parseSI,
  parseSO,
  ScrollBar,
  setMcaFeedback,
  setMraMessage,
  setSelectedViewOption,
  syncMcaInputAction,
  TBE,
  TBP,
  useCustomEventListener,
  useHitArea,
  useViewOptionSelected,
} from "@poscon/shared-frontend";
import { Rectangle } from "pixi.js";
import { layerZIndexMap } from "~/layerZIndexMap";
import { WebviewWindow } from "@tauri-apps/api/webviewWindow";
import { UP_ARROW, DOWN_ARROW } from "@poscon/eram-cpdlc";
import { chunkRows, assert, OBSCURE_WEATHER_SYMBOL } from "@poscon/shared-types";
import { EramConnection } from "@poscon/shared-types/poscon";
import { InteractiveContainer } from "components/utils/InteractiveContainer";

const view = "MESSAGE_COMPOSE_AREA";

const optionMap = {
  paLines: counterOption(view, "paLines", "PA LINES", 2, 12, 12),
  width: counterOption(view, "width", "WIDTH", 25, 50, 8, "delta", 25),
  font: fontOption(view),
  bright: brightOption(view),
};

export const MessageComposeArea = () => {
  const ref = useRef<PixiContainer>(null);
  const connection = useRootSelector(connectionSelector) as EramConnection;
  const isOpen = useRootSelector((state) => viewSelector(state, view).open);
  const dispatch = useRootDispatch();
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const mcaFeedback = useRootSelector(mcaFeedbackSelector);
  const _pos = useRootSelector((state) => viewPositionSelector(state, view));
  const [mcaInput, setMcaInput] = useState<EramMessageElement[]>([]);
  const { value: insertMode, toggle: toggleInsertMode, setValue: setInsertMode } = useBoolean(true);
  const [cursorPosition, setCursorPosition] = useState(0);
  const zStack = useRootSelector(zStackSelector);
  const viewOptions = useRootSelector((state) => viewOptionSelector(state, view));
  const { selected: showOptions } = useViewOptionSelected(view);
  const zIndex = useZIndex(view, ref);
  const isProcessingCommand = useRootSelector(isProcessingCommandSelector);
  const fontFamily = eramFontNameMap[viewOptions.font as EramFontSize];
  const { width: fontWidth, height: fontHeight } = eramFontDimensionMap[fontFamily];
  const [paScrollOffset, setPaScrollOffset] = useState(0);
  const recallMessageRef = useRef<EramMessageElement[]>([]);

  const showArrows = mcaInput.length > viewOptions.width * viewOptions.paLines;

  useEffect(() => {
    if (paScrollOffset > 0 && !showArrows) {
      setPaScrollOffset(0);
    }
    if ((paScrollOffset + viewOptions.paLines) * viewOptions.width > mcaInput.length) {
      setPaScrollOffset(
        Math.max(0, Math.floor((mcaInput.length + 1) / viewOptions.width) - viewOptions.paLines),
      );
    }
    if (showArrows && cursorPosition > (paScrollOffset + viewOptions.paLines - 1) * viewOptions.width - 1) {
      setPaScrollOffset(Math.floor(cursorPosition / viewOptions.width) - viewOptions.paLines + 1);
    }
    if (showArrows && cursorPosition < paScrollOffset * viewOptions.width) {
      setPaScrollOffset(Math.floor(cursorPosition / viewOptions.width));
    }
  }, [cursorPosition, mcaInput.length, paScrollOffset, showArrows, viewOptions.paLines, viewOptions.width]);

  const paHeight = Math.min(viewOptions.paLines, Math.max(2, Math.ceil(mcaInput.length / viewOptions.width)));

  const showSuccessIndicator = mcaFeedback?.isSuccess !== undefined;
  const feedbackRows = mcaFeedback
    ? [
        `${showSuccessIndicator ? "  " : ""}${mcaFeedback.line1 ?? (mcaFeedback.isSuccess === undefined ? "" : mcaFeedback.isSuccess ? "ACCEPT" : "REJECT")}`,
        ...mcaFeedback.lines,
      ]
        .flatMap((l) => chunkRows(l, viewOptions.width))
        .filter((s) => s.length > 0)
    : [];
  let width = fontWidth * viewOptions.width + 7;
  const height = fontHeight * (paHeight + Math.max(4, feedbackRows.length)) + 3;

  const hitAreaRef = useHitArea(0, 0, width, height);

  if (showArrows) {
    width += fontWidth + 4;
  }

  const { startDrag } = useDragging(ref, view);

  const pos =
    width && height
      ? {
          x: Math.min(_pos.x, windowWidth - width - 2),
          y: Math.min(_pos.y, windowHeight - height - 2),
        }
      : _pos;

  const parseEramMessage = useCallback(
    (msg: EramMessageElement[], insertUplink = false) => {
      recallMessageRef.current = msg;
      const elements = getEramMessageElements(msg);

      const [command] = elements;

      assert(command, "command is empty");

      switch (command.token) {
        case "SI":
          dispatch(parseSI(elements));
          break;
        case "SO":
          dispatch(parseSO(elements));
          break;
        default:
          if (insertUplink) {
            elements.splice(1, 0, { token: "/U" });
          }
          dispatch(processEramMessage(elements, false));
          break;
      }
    },
    [dispatch],
  );

  const inputIsLocked =
    (connection?.hasActiveDataConnection && !connection.isActive) ||
    (connection?.sectorId && connection?.role === "Observer");

  useEventListener("keydown", (event: KeyboardEvent) => {
    if (event.code === "KeyI" && event.ctrlKey && event.shiftKey) {
      // open devtools
      return;
    }
    event.preventDefault();
    if (event.ctrlKey && event.code === "KeyR") {
      window.location.reload();
      return;
    }
    if (inputIsLocked) {
      return;
    }
    // special keys / key remappings
    switch (event.code) {
      case "ControlLeft":
        dispatch(setMraMessage([]));
        break;
      // case "KeyS":
      //   if (event.ctrlKey && event.altKey) {
      //     dispatch(setToggleButtonValue({ buttonId: "SETTINGS_MENU", newValue: true }));
      //     return;
      //   }
      //   break;
      case "Enter":
        if (event.altKey && window.__TAURI__) {
          WebviewWindow.getCurrent()
            .isDecorated()
            .then(async (isDecorated) => {
              await WebviewWindow.getCurrent().setFullscreen(false);
              void WebviewWindow.getCurrent().setDecorations(!isDecorated);
            });
          return;
        }
        if (event.ctrlKey && window.__TAURI__) {
          WebviewWindow.getCurrent()
            .isFullscreen()
            .then(async (isFullscreen) => {
              if (!isFullscreen) {
                await WebviewWindow.getCurrent().setDecorations(true);
              }
              void WebviewWindow.getCurrent().setFullscreen(!isFullscreen);
            });
          return;
        }
    }
    if (isProcessingCommand) {
      return;
    }
    if (zIndex < zStack.length - 1 || zStack.length === 0) {
      dispatch(pushZStack(view));
    }
    if (!isOpen) {
      dispatch(openView(view));
    }
    const key = event.key;
    let charToInsert = key.toUpperCase();
    if (event.altKey === event.getModifierState("CapsLock")) {
      switch (event.code) {
        case "Digit1":
          charToInsert = OBSCURE_WEATHER_SYMBOL;
          break;
        case "Digit2":
          if (event.shiftKey) {
            if (!errorTone.isPlaying) {
              errorTone.increaseVolume();
              void errorTone.tryPlay();
            }
            return;
          }
          charToInsert = UP_ARROW;
          break;
        case "Digit3":
          if (event.shiftKey) {
            if (!errorTone.isPlaying) {
              errorTone.decreaseVolume();
              void errorTone.tryPlay();
            }
            return;
          }
          charToInsert = DOWN_ARROW;
          break;
        case "Digit4":
          setMcaInput(recallMessageRef.current);
          setCursorPosition(0);
          return;
        // HOME
        case "Digit5":
          if (window.__TAURI__) {
            void WebviewWindow.getCurrent().setCursorPosition(
              new LogicalPosition(window.innerWidth / 2 - 1, window.innerHeight / 2 - 1),
            );
          }
          return;
        case "Digit6":
        case "Digit7":
        case "Digit8":
          return;
        case "Digit9":
          if (event.shiftKey) {
            dispatchInsertCommandEvent(stringToTokenArray("SO"));
            return;
          }
          dispatchInsertCommandEvent(stringToTokenArray("SI"));
          return;
      }
    }
    switch (key) {
      case "Pause":
      case "Enter":
        if (mcaInput.length > 0) {
          void parseEramMessage(mcaInput, key === "Pause");
        } else {
          dispatch(setMcaFeedback(null));
        }
        break;
      case "Escape":
        setMcaInput([]);
        setCursorPosition(0);
        dispatch(setMcaFeedback(null));
        break;
      case "ArrowLeft":
        if (cursorPosition % viewOptions.width === 0) {
          setPaScrollOffset((prevOffset) => Math.max(0, prevOffset - 1));
        }
        setCursorPosition((prevPosition) => Math.max(0, prevPosition - 1));
        break;
      case "ArrowUp":
        setCursorPosition((prevPosition) => Math.max(0, prevPosition - viewOptions.width));
        break;
      case "ArrowDown":
        setCursorPosition((prevPosition) => Math.min(mcaInput.length, prevPosition + viewOptions.width));
        break;
      case "ArrowRight":
        if (cursorPosition % viewOptions.width === viewOptions.width - 1) {
          setPaScrollOffset((prevOffset) =>
            Math.min(
              prevOffset + 1,
              Math.max(0, Math.floor((mcaInput.length + 1) / viewOptions.width) - viewOptions.paLines),
            ),
          );
        }
        setCursorPosition((prevPosition) => Math.min(prevPosition + 1, mcaInput.length));
        break;
      case "Backspace":
        if (cursorPosition > 0) {
          setMcaInput((prevValue) =>
            prevValue.slice(0, cursorPosition - 1).concat(prevValue.slice(cursorPosition)),
          );
          setCursorPosition((prevPosition) => Math.max(0, prevPosition - 1));
        }
        break;
      case "Insert":
        toggleInsertMode();
        break;
      case "Delete":
        setMcaInput((prevValue) =>
          prevValue.slice(0, cursorPosition).concat(prevValue.slice(cursorPosition + 1)),
        );
        break;
      default:
        if (charToInsert.length === 1) {
          setMcaInput((prevValue) =>
            [...prevValue.slice(0, cursorPosition), { token: charToInsert }].concat(
              prevValue.slice(cursorPosition + (insertMode ? 1 : 0)),
            ),
          );
          setCursorPosition((prevPosition) => prevPosition + 1);
        }
    }
  });

  useCustomEventListener("insertcommand", (event: InsertCommandEvent) => {
    setMcaInput(event.detail.command);
    setCursorPosition(event.detail.command.length);
  });

  useCustomEventListener("recallcommand", (event: RecallCommandEvent) => {
    const recall = event.detail;
    setCursorPosition(recall?.cursorPosition ?? 0);
    setMcaInput(recall?.msg ?? []);
  });

  useEffect(() => {
    dispatch(
      syncMcaInputAction({
        mcaInput,
        cursorPosition,
        insertMode,
      }),
    );
  }, [cursorPosition, dispatch, insertMode, mcaInput]);

  useCustomEventListener("ui:sync:mcaInput", (event) => {
    const { mcaInput: newInput, cursorPosition: newCursorPosition, insertMode: newInsertMode } = event.detail;
    setMcaInput(newInput);
    setCursorPosition(newCursorPosition);
    setInsertMode(newInsertMode);
  });

  const tint = computeColor(colorNameMap.white, viewOptions.bright / 100);

  const cursorX = (cursorPosition % viewOptions.width) * fontWidth + 4;
  const cursorY =
    Math.min(Math.floor(cursorPosition / viewOptions.width), viewOptions.paLines - 1) * fontHeight;

  return isOpen ? (
    <ViewOptionContextProvider options={viewOptions}>
      <InteractiveContainer
        label="MCA"
        x={pos.x}
        y={pos.y}
        zIndex={zIndex}
        ref={ref}
        hitArea={hitAreaRef.current}
      >
        <graphics
          eventMode="static"
          draw={(graphics) => {
            graphics.clear();
            graphics.rect(0, 0, width, height).fill(0).stroke({ width: 1, color: baseBorderColor });
            graphics
              .moveTo(0, fontHeight * paHeight + 4)
              .lineTo(width, fontHeight * paHeight + 4)
              .stroke({ width: 1, color: baseBorderColor });
          }}
          onMouseDown={(event: FederatedPointerEvent) => {
            switch (event.button) {
              case TBE:
                event.preventDefault();
                dispatch(setSelectedViewOption(view));
                break;
              case TBP:
                void startDrag(event);
                break;
            }
          }}
        />
        <graphics
          label="MCA_CURSOR"
          x={cursorX}
          y={cursorY}
          eventMode="none"
          draw={(graphics) => {
            graphics.clear();
            if (insertMode) {
              graphics.moveTo(0, fontHeight).lineTo(fontWidth, fontHeight);
            } else {
              graphics.moveTo(0, 2).lineTo(0, fontHeight).lineTo(fontWidth, fontHeight).lineTo(fontWidth, 2);
            }
            graphics.stroke({ width: 1, color: tint });
          }}
        />
        {showArrows && (
          <ScrollBar
            x={width - fontWidth - 3}
            y={2}
            height={paHeight * fontHeight}
            scrollUpDisabled={paScrollOffset === 0}
            scrollDownDisabled={(paScrollOffset + viewOptions.paLines) * viewOptions.width > mcaInput.length}
            scrollUp={() => {
              setPaScrollOffset(Math.max(0, paScrollOffset - 1));
              setCursorPosition(Math.max(0, cursorPosition - viewOptions.width));
            }}
            scrollDown={() => {
              setPaScrollOffset(
                Math.min(
                  paScrollOffset + 1,
                  Math.ceil((mcaInput.length + 1) / viewOptions.width) - (viewOptions.paLines - 1),
                ),
              );
              setCursorPosition(Math.min(mcaInput.length, cursorPosition + viewOptions.width));
            }}
            tint={tint}
            disabledTint={computeColor(colorNameMap.grey, viewOptions.bright / 100)}
            borderTint={baseBorderColor}
            fontSize={viewOptions.font as EramFontSize}
          />
        )}
        <bitmapText
          label="MCA_PREVIEW_AREA"
          text={chunkEramMessage(mcaInput, viewOptions.width)
            .slice(paScrollOffset, paScrollOffset + viewOptions.paLines)
            .join("\n")}
          x={4}
          y={1}
          eventMode="none"
          style={{
            ...getBitmapTextStyles(fontFamily),
            fill: tint,
          }}
        />
        {mcaFeedback && (
          <>
            {mcaFeedback.isSuccess !== undefined && (
              <bitmapText
                label="MCA_FEEDBACK_AREA_INDICATOR"
                text={mcaFeedback.isSuccess ? "\u0083" : "X"}
                x={4}
                y={fontHeight * paHeight + 5}
                eventMode="none"
                style={{
                  ...getBitmapTextStyles(fontFamily),
                  fill: computeColor(
                    mcaFeedback.isSuccess ? colorNameMap.green : colorNameMap.red,
                    viewOptions.bright / 100,
                  ),
                }}
              />
            )}
            <bitmapText
              label="MCA_FEEDBACK_AREA_LINES"
              text={feedbackRows.join("\n")}
              x={4}
              y={fontHeight * paHeight + 5}
              eventMode="none"
              style={{
                ...getBitmapTextStyles(fontFamily),
                fill: tint,
              }}
            />
          </>
        )}
      </InteractiveContainer>
      {showOptions && (
        <container x={pos.x} y={pos.y} zIndex={layerZIndexMap.viewMenu}>
          <ViewMenu view={view} parentWidth={width} options={optionMap} />
        </container>
      )}
    </ViewOptionContextProvider>
  ) : null;
};
