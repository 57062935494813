import { createListenerMiddleware } from "@reduxjs/toolkit";
import type { RootDispatch, RootState } from "./store";
import { setMcaFeedback, setMraMessage } from "@poscon/shared-frontend";
import { openView, pushZStack } from "./slices/edstSlice";

export const listenerMiddleware = createListenerMiddleware();
export const startListening = listenerMiddleware.startListening.withTypes<RootState, RootDispatch>();

startListening({
  predicate: (action) => setMcaFeedback.match(action) || setMraMessage.match(action),
  effect: (action, { dispatch }) => {
    const viewToOpen = setMcaFeedback.match(action) ? "MESSAGE_COMPOSE_AREA" : "MESSAGE_RESPONSE_AREA";
    dispatch(openView(viewToOpen));
    dispatch(pushZStack(viewToOpen));
  },
});