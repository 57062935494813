import React from "react";
import { View } from "components/utils/View";
import type { ViewHeaderProps, ViewItemOption } from "@poscon/shared-frontend";
import {
  setSelectedViewOption,
  selectedViewOptionSelector,
  ViewItemOptionContainer,
  createItemOption,
  colorNameMap,
  saaListSelector,
  MenuElement,
  ScrollBar,
  useScrollProps,
  artccIdSelector,
} from "@poscon/shared-frontend";
import { defaultTint, font2Dimension } from "~/utils/constants";
import type { Day, SaaActivation, SaaId, SaaSchedule } from "@poscon/shared-types/eram";
import { formatScheduleTime, getTimeUntilSaaScheduleActivation } from "@poscon/shared-types/eram";
import { RadioButton } from "components/utils/RadioButton";
import { setAddSaaAltId, setViewPosition } from "~redux/slices/edstSlice";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { eramHubConnection } from "~/eramHubConnection";
import { addAspAltBaseHeight, addAspAltWidth } from "components/prompts/AddAirspaceAltitude";

const view = "AIRSPACE_STATUS";

const { width: fontWidth, height: fontHeight } = font2Dimension;

const radioSize = fontHeight - 4;

const maxNameLength = 30;

const reqX = fontWidth * 3;
const reqWidth = fontWidth * 3 + 4;
const onX = reqX + reqWidth + fontWidth;
const onWidth = fontWidth * 2 + 4;
const offX = onX + onWidth + fontWidth;
const offWidth = fontWidth * 3 + 4;
const scheduledX = offX + offWidth + fontWidth;
const scheduledWidth = fontWidth * 3 + 4;
const nameX = scheduledX + scheduledWidth + fontWidth;
const nameWidth = fontWidth * maxNameLength + 4;
const statusX = nameX + nameWidth + 4;
const statusWidth = fontWidth * 7;
const bottomAltX = statusX + statusWidth + 4;
const bottomAltWidth = fontWidth * 6 + 4;
const topAltX = bottomAltX + fontWidth * 8 - 2;
const topAltWidth = fontWidth * 3 + 4;
const scheduleX = topAltX + topAltWidth + 8;
const scheduleWidth = fontWidth * 25;

const colFormatMap = {
  REQ: { x: reqX, width: reqWidth },
  ON: { x: onX, width: onWidth },
  OFF: { x: offX, width: offWidth },
  SCH: { x: scheduledX, width: scheduledWidth },
  NAME: { x: nameX, width: nameWidth },
  STATUS: { x: statusX, width: statusWidth },
  BOTTOM: { x: bottomAltX, width: bottomAltWidth },
  TOP: { x: topAltX, width: topAltWidth },
  SCHEDULE: { x: scheduleX, width: scheduleWidth },
};

const numRows = 30;
const width = scheduleX + scheduleWidth + 4;
const height = fontHeight + 15 + (fontHeight + 3) * numRows;

export function createSaaActivationItemOptions(
  saaId: SaaId,
  activation: SaaActivation,
  closeMenu: () => void,
) {
  const itemOptions: ViewItemOption[] = [];
  if (activation.type === "PENDING") {
    itemOptions.push(
      createItemOption(
        "ACTIVATE",
        () => {
          eramHubConnection.emit("updateSaaActivation", saaId, activation.id, {
            type: "HOT",
            floor: activation.floor,
            ceiling: activation.ceiling,
          });
          closeMenu();
        },
        { isDefaultSelection: true },
      ),
      createItemOption("CANCEL", () => {
        eramHubConnection.emit("removeSaaActivation", saaId, activation.id);
      }),
    );
  } else {
    itemOptions.push(
      createItemOption(
        "DEACTIVATE",
        () => {
          eramHubConnection.emit("removeSaaActivation", saaId, activation.id);
        },
        { isDefaultSelection: true },
      ),
    );
  }
  itemOptions.push(
    createItemOption("EDIT", () => {
      // TODO: what should this do?
    }),
  );
  return itemOptions;
}

export const AirspaceStatus = () => {
  const artccId = useRootSelector(artccIdSelector);
  const dispatch = useRootDispatch();
  const saaList = useRootSelector(saaListSelector);
  const selectedViewOption = useRootSelector(selectedViewOptionSelector);

  const sortedSaaList = saaList.toSorted((a, b) => a.sortId.localeCompare(b.sortId));

  const scrollProps = useScrollProps(
    "airspaceStatus",
    sortedSaaList.reduce(
      (acc, saa) => acc + Math.max(saa.schedules.flatMap((s) => s.daySchedules).length, 1),
      0,
    ),
    numRows,
    { scrollDelta: Math.floor(numRows / 2) },
  );

  const headerButtons: ViewHeaderProps["buttons"] = [
    { text: "FACILITY-WIDE", width: 13 * fontWidth + 8, disabled: true },
    { text: "FACILITIES", width: 10 * fontWidth + 8, disabled: true },
  ];

  let yIndex = 0;

  return (
    <View
      title="AIRSPACE STATUS - EXPANDED"
      viewMenuTitle="AS STAT"
      view={view}
      absWidth={width}
      height={height}
      headerButtons={headerButtons}
    >
      <graphics
        draw={(graphics) => {
          graphics.clear();
          graphics
            .moveTo(0, fontHeight + 8)
            .lineTo(width, fontHeight + 8)
            .stroke({ width: 1, color: defaultTint });
        }}
      />
      {Object.entries(colFormatMap).map(([col, { x, width }]) => (
        <MenuElement key={col} text={col} textX={2} x={x} y={4} width={width} borderColor={0} fillColor={0} />
      ))}
      <ScrollBar x={2} y={fontHeight + 10} height={height - fontHeight - 12} {...scrollProps} />
      <container y={fontHeight + 15}>
        {sortedSaaList.map((saa) => {
          const schedules = saa.schedules.reduce<(SaaSchedule & { day: Day; timeUntilActivation: number })[]>(
            (acc, schedule) => {
              // eslint-disable-next-line no-restricted-syntax
              for (const _daySchedule of schedule.daySchedules) {
                const daySchedule = {
                  day: schedule.day,
                  ..._daySchedule,
                };
                const timeUntilActivation = getTimeUntilSaaScheduleActivation(daySchedule, Date.now());
                // less that 48 hours
                if (timeUntilActivation < 172800) {
                  acc.push({ ...daySchedule, timeUntilActivation });
                }
              }
              return acc;
            },
            [],
          );
          const active =
            saa.activations.some((status) => status.type === "HOT") ||
            schedules.some((daySchedule) => daySchedule.timeUntilActivation === 0);
          const pending = saa.activations.some((status) => status.type === "PENDING");
          const radioTint = saa.artccId === artccId ? colorNameMap.grey : colorNameMap.darkGrey;
          let tint = saa.artccId === artccId ? colorNameMap.grey : colorNameMap.darkGrey;
          if (!saa.forcedOff && (active || pending)) {
            tint = active ? colorNameMap.orange : colorNameMap.yellow;
          }
          let nameText = saa.saaName.slice(0, 30);
          if (saa.artccId !== artccId) {
            nameText = `${nameText.slice(0, 23)} (${saa.artccId.slice(1)})`;
          }

          const y = (fontHeight + 3) * (yIndex - scrollProps.scroll);
          const _yIndex = yIndex;
          yIndex += Math.max(schedules.length + saa.activations.length, 1);
          return (
            scrollProps.scroll < yIndex &&
            _yIndex < scrollProps.scroll + numRows && (
              <container key={saa.saaId} y={Math.max(y, 0)}>
                <RadioButton
                  x={Math.floor(reqX + reqWidth / 2)}
                  y={Math.floor(fontHeight / 2) + 1}
                  selectedTint={radioTint}
                  size={radioSize}
                  selected={pending}
                  onmousedown={() => {
                    dispatch(
                      setViewPosition({
                        view: "ADD_AIRSPACE_ALT_MENU",
                        pos: {
                          x: window.innerWidth / 2 - addAspAltWidth / 2,
                          y: window.innerHeight / 2 - addAspAltBaseHeight / 2,
                        },
                      }),
                    );
                    dispatch(setAddSaaAltId(saa.saaId));
                  }}
                />
                <RadioButton
                  x={Math.floor(onX + onWidth / 2)}
                  y={Math.floor(fontHeight / 2) + 1}
                  selectedTint={radioTint}
                  size={radioSize}
                  selected={!saa.forcedOff && active}
                  onmousedown={() => {
                    if (saa.forcedOff) {
                      eramHubConnection.emit("setSaaForcedOff", saa.saaId, false);
                    }
                    if (!active && artccId) {
                      eramHubConnection.emit("addSaaActivation", saa.saaId, {
                        artccId,
                        type: "HOT",
                        floor: saa.alwaysOnMinAltitude,
                        ceiling: saa.alwaysOnMaxAltitude,
                      });
                    }
                  }}
                />
                <RadioButton
                  x={Math.floor(offX + offWidth / 2)}
                  y={Math.floor(fontHeight / 2) + 1}
                  selectedTint={radioTint}
                  size={radioSize}
                  selected={saa.forcedOff || (!active && !pending)}
                  onmousedown={() => {
                    eramHubConnection.emit("setSaaForcedOff", saa.saaId, !saa.forcedOff);
                  }}
                />
                <RadioButton
                  x={Math.floor(scheduledX + scheduledWidth / 2)}
                  y={Math.floor(fontHeight / 2) + 1}
                  selectedTint={radioTint}
                  size={radioSize}
                  selected={schedules.length > 0}
                />
                <MenuElement
                  text={nameText}
                  x={nameX}
                  textX={2}
                  width={nameWidth}
                  tint={tint}
                  borderColor={0x000000}
                  fillColor={0x000000}
                />
                {((saa.activations.length === 0 && !active) || saa.forcedOff) && (
                  <MenuElement
                    text="OFF"
                    x={statusX}
                    tint={tint}
                    textX={2}
                    width={statusWidth}
                    borderColor={0x000000}
                    fillColor={0x000000}
                  />
                )}
                {saa.activations.map((activation, i) => {
                  const y = (fontHeight + 3) * (i + Math.min(0, _yIndex - scrollProps.scroll));
                  const tint = activation.type === "PENDING" ? colorNameMap.yellow : colorNameMap.orange;
                  const selected =
                    selectedViewOption === `${view}/airspaceStatus/${saa.saaId}/${activation.id}`;

                  return (
                    scrollProps.scroll <= _yIndex + i &&
                    _yIndex + i < scrollProps.scroll + numRows && (
                      <container key={activation.id} y={y}>
                        {!saa.forcedOff && (
                          <MenuElement
                            text={activation.type === "PENDING" ? "PEND" : "ON"}
                            x={statusX}
                            textX={2}
                            width={statusWidth}
                            tint={tint}
                            borderColor={0}
                            fillColor={0}
                          />
                        )}
                        <MenuElement
                          text={
                            (activation.floor / 100).toString().padStart(3, "0").padEnd(8, " ") +
                            (activation.ceiling / 100).toString().padStart(3, "0")
                          }
                          x={bottomAltX}
                          textX={2}
                          width={fontWidth * 11 + 4}
                          tint={tint}
                          selectReverseVideo
                          selected={selected}
                          borderColor={selected ? tint : 0}
                          fillColor={0x000000}
                          onmousedown={() => {
                            dispatch(
                              setSelectedViewOption(`${view}/airspaceStatus/${saa.saaId}/${activation.id}`),
                            );
                          }}
                        />
                        {selected && (
                          <ViewItemOptionContainer
                            baseX={bottomAltX}
                            xOffset={topAltX + topAltWidth - bottomAltX + 2}
                            options={createSaaActivationItemOptions(saa.saaId, activation, () =>
                              dispatch(setSelectedViewOption(null)),
                            )}
                          />
                        )}
                      </container>
                    )
                  );
                })}
                {schedules.map((daySchedule, j) => {
                  const i = saa.activations.length + j;
                  const scheduleStr = formatScheduleTime(daySchedule);
                  const top = (daySchedule.scheduleUpperAlt / 100).toString().padStart(3, "0");
                  const bottom = (daySchedule.scheduleLowerAlt / 100).toString().padStart(3, "0");
                  const y = (fontHeight + 3) * (i + Math.min(0, _yIndex - scrollProps.scroll));
                  const scheduleStatus = daySchedule.timeUntilActivation === 0 ? "ON" : "OFF";
                  const scheduleTint = !saa.forcedOff && scheduleStatus === "ON" ? colorNameMap.orange : tint;
                  return (
                    scrollProps.scroll <= _yIndex + i &&
                    _yIndex + i < scrollProps.scroll + numRows && (
                      <container key={i} y={y}>
                        {!saa.forcedOff && scheduleStatus === "ON" && (
                          <MenuElement
                            x={statusX}
                            textX={2}
                            width={statusWidth}
                            text={scheduleStatus}
                            tint={scheduleTint}
                            borderColor={0}
                            fillColor={0}
                          />
                        )}
                        <MenuElement
                          text={bottom.padEnd(8, " ") + top}
                          tint={scheduleTint}
                          x={bottomAltX}
                          textX={2}
                          width={fontWidth * 11 + 4}
                          borderColor={0}
                          fillColor={0}
                        />
                        <MenuElement
                          text={scheduleStr}
                          tint={scheduleTint}
                          x={scheduleX}
                          textX={2}
                          width={scheduleWidth}
                          borderColor={0}
                          fillColor={0}
                        />
                      </container>
                    )
                  );
                })}
              </container>
            )
          );
        })}
      </container>
    </View>
  );
};
