import React from "react";
import type { ColorSource, Graphics as PixiGraphics } from "pixi.js";
import { colorNameMap, useFocused, useHitArea } from "@poscon/shared-frontend";
import { Rectangle } from "pixi.js";

type RadioButtonProps = {
  x?: number;
  y?: number;
  size?: number;
  borderSize?: number;
  borderTint?: ColorSource;
  selectedTint?: ColorSource;
  selected: boolean;
  onmousedown?: () => void;
};

export const RadioButton = ({
  x = 0,
  y = 0,
  selected,
  onmousedown,
  size = 8,
  borderSize = 1,
  borderTint = colorNameMap.darkGrey,
  selectedTint = colorNameMap.grey,
}: RadioButtonProps) => {
  const ref = React.useRef<PixiGraphics>(null);
  const focused = useFocused(ref);
  const hitAreaRef = useHitArea(-size / 2, -size / 2, size, size);
  return (
    <graphics
      ref={ref}
      x={x}
      y={y}
      eventMode="static"
      onMouseDown={onmousedown}
      hitArea={hitAreaRef.current}
      draw={(graphics) => {
        graphics.clear();
        graphics
          .circle(0, 0, Math.floor(size / 2))
          .fill(selected ? selectedTint : 0)
          .stroke({ width: borderSize, color: focused ? colorNameMap.white : borderTint });
      }}
    />
  );
};
