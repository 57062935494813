import type { CSSProperties } from "react";
import React, { useCallback, useRef, useState } from "react";
import type { AtMostOne } from "@poscon/shared-types";
import { viewPositionSelector } from "~redux/slices/edstSlice";
import { useDragging } from "hooks/useDragging";
import { useCenterCursor, useFocused, baseBorderColor } from "@poscon/shared-frontend";
import { useRootDispatch, useRootSelector } from "~redux/hooks";
import { useZIndex } from "hooks/useZIndex";
import { aselFlightplanSelector } from "~redux/slices/aircraftSlice";
import type { Container as PixiContainer } from "pixi.js";
import { font2, font2Dimension } from "~/utils/constants";
import { MenuTitleBar } from "components/utils/MenuTitleBar";
import { InteractiveContainer } from "~/components/utils/InteractiveContainer";

const fontName = font2;
const { width: fontWidth, height: fontHeight } = font2Dimension;
const width = 88 * fontWidth;
const height = 20 * fontHeight;

const view = "EQUIPMENT_TEMPLATE_MENU";

type MenuOption = "surv" | "nav" | "comm" | "appServ";

type EquipmentTemplateRowProps = {
  margin?: CSSProperties["margin"];
  buttonText: string;
  selected: boolean;
  text?: string;
  toggleSelect(): void;
} & AtMostOne<Record<"diamond" | "circle", boolean>>;

export const EquipmentTemplateRow = ({
  margin,
  toggleSelect,
  selected,
  circle,
  diamond,
  buttonText,
  text,
}: EquipmentTemplateRowProps) => {
  return null;
  // return (
  //   <div className={eqpStyles.row} style={{ margin }}>
  //     <div className={eqpStyles.contentCol} onmousedown={toggleSelect}>
  //       <div className={clsx(indicatorClass, { selected })} />
  //       {buttonText}
  //     </div>
  //     {text ?? ""}
  //   </div>
  // );
};

export type EquipmentTemplateBodyProps = {
  setReset: (callback: () => void) => void;
};

export const EquipmentTemplateMenu = () => {
  const dispatch = useRootDispatch();
  const pos = useRootSelector((state) => viewPositionSelector(state, view));
  const fp = useRootSelector((state) => aselFlightplanSelector(state));

  const [selectedMenu, setSelectedMenu] = useState<MenuOption>("nav");
  const ref = useRef<PixiContainer>(null);
  const zIndex = useZIndex(view, ref);
  const focused = useFocused(ref);
  useCenterCursor(ref);
  const { startDrag } = useDragging(ref, view, "mouseup");
  const resetRef = useRef<() => void>(undefined);

  const setReset = useCallback((callback: () => void) => {
    resetRef.current = callback;
  }, []);

  return (
    <InteractiveContainer ref={ref} x={pos.x} y={pos.y} zIndex={zIndex} sortableChildren>
      <MenuTitleBar
        title={"Equipment Template".toUpperCase()}
        width={width}
        onTitleMouseDown={startDrag}
        view={view}
      />
      <container y={fontHeight + 2}>
        <graphics
          draw={(graphics) => {
            graphics.clear();
            graphics.rect(0, 0, width, height).fill(0).stroke({ width: 1, color: baseBorderColor });
          }}
        />
      </container>
    </InteractiveContainer>
  );
  // return (
  //   <div className={clsx(eqpStyles.root, { noPointerEvents: anyDragging })} ref={ref} style={{ ...pos, zIndex }}>
  //     <div className={clsx(optionStyles.header, { focused })} onmousedown={startDrag}>
  //       Equipment Template
  //     </div>
  //     <div className={clsx(optionStyles.body)}>
  //       <div className={optionStyles.fidRow}>
  //         {fp && `${fp.callsign} ${fp.aircraftType}/${fp.faaterminalequipmentsuffix}`}
  //       </div>
  //       <div className={eqpStyles.row}>
  //         <EdstTemplateButton10ch
  //           selected={selectedMenu === MenuOptions.surv}
  //           content="SURV"
  //           onmousedown={() => setSelectedMenu(MenuOptions.surv)}
  //         />
  //         <EdstTemplateButton10ch
  //           selected={selectedMenu === MenuOptions.nav}
  //           content="NAV"
  //           onmousedown={() => setSelectedMenu(MenuOptions.nav)}
  //         />
  //         <EdstTemplateButton10ch
  //           selected={selectedMenu === MenuOptions.comm}
  //           content="COMM"
  //           onmousedown={() => setSelectedMenu(MenuOptions.comm)}
  //         />
  //         <EdstTemplateButton10ch
  //           selected={selectedMenu === MenuOptions.appServ}
  //           content="APP/SERV"
  //           onmousedown={() => setSelectedMenu(MenuOptions.appServ)}
  //         />
  //         <div className={clsx(optionStyles.col, "right")}>
  //           <EdstButton content="Reset" onmousedown={resetRef.current} />
  //         </div>
  //       </div>
  //       {selectedMenu === MenuOptions.surv && <EquipmentSurvTemplate setReset={setReset} />}
  //       {selectedMenu === MenuOptions.nav && <EquipmentNavTemplate setReset={setReset} />}
  //       {selectedMenu === MenuOptions.comm && <EquipmentCommTemplate setReset={setReset} />}
  //       {selectedMenu === MenuOptions.appServ && <EquipmentAppServTemplate setReset={setReset} />}
  //       <div className={eqpStyles.bottomRow}>
  //         <div className={optionStyles.col}>
  //           <EdstButton disabled content="OK" />
  //         </div>
  //         <div className={clsx(optionStyles.col, "right")}>
  //           <EdstButton content="Cancel" onmousedown={() => dispatch(closeView("EQUIPMENT_TEMPLATE_MENU"))} />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};
