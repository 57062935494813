import React, { useCallback, useLayoutEffect, useState } from "react";
import type { Rectangle } from "pixi.js";
import type { Coordinate } from "@poscon/shared-types";
import type { AnchorDirection } from "hooks/useResizable";
import { colorNameMap, useStableCallback } from "@poscon/shared-frontend";
import { draggingOutline } from "~/draggingOutline";
import { useApplication } from "@pixi/react";

type DragContextValue = {
  anyDragging: boolean;
  setAnyDragging: React.Dispatch<React.SetStateAction<boolean>>;
  draggingHandler: (event: MouseEvent, offset?: Coordinate) => void;
  resizeHandler: (
    event: MouseEvent,
    anchorX: AnchorDirection,
    anchorY: AnchorDirection,
    bounds: Rectangle,
  ) => void;
  getDraggingOutlinePositionAndDimension: () => {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  setDraggingOutlinePositionAndDimension: (x: number, y: number, width: number, height: number) => void;
  setDraggingOutlineVisible: (visible: boolean) => void;
};
export const DragContext = React.createContext<DragContextValue | null>(null);

type DragContextProviderProps = {
  children: React.ReactNode;
};
export const DragContextProvider = ({ children }: DragContextProviderProps) => {
  const [anyDragging, setAnyDragging] = useState(false);

  const app = useApplication();

  useLayoutEffect(() => {
    if (!app.app.stage.children.includes(draggingOutline)) {
      app.app.stage.addChild(draggingOutline);
    }
  }, [app.app.stage]);

  const setDraggingOutlinePositionAndDimension = useCallback(
    (x: number, y: number, width: number, height: number) => {
      draggingOutline.x = x;
      draggingOutline.y = y;
      draggingOutline.clear();
      draggingOutline.rect(0, 0, width, height).stroke({ width: 1, color: colorNameMap.white });
    },
    [],
  );

  const setDraggingOutlineVisible = useCallback((visible: boolean) => {
    draggingOutline.visible = visible;
  }, []);

  const getDraggingOutlinePositionAndDimension = useStableCallback(() => {
    return {
      x: draggingOutline.x,
      y: draggingOutline.y,
      height: draggingOutline.height,
      width: draggingOutline.width,
    };
  });

  const draggingHandler = useCallback((event: MouseEvent, offset: [number, number] = [0, 0]) => {
    event.stopPropagation();
    draggingOutline.x = Math.max(1, event.pageX + offset[0]);
    draggingOutline.y = Math.max(0, event.pageY + offset[1]);
  }, []);

  const resizeHandler = useStableCallback(
    (event: MouseEvent, anchorX: AnchorDirection, anchorY: AnchorDirection, bounds: Rectangle) => {
      event.stopPropagation();
      const newX = anchorX === -1 ? event.pageX : bounds.left;
      const newY = anchorY === -1 ? event.pageY : bounds.top;
      let newWidth: number;
      switch (anchorX) {
        case -1:
          newWidth = bounds.width - (event.pageX - bounds.x);
          break;
        case 1:
          newWidth = event.pageX - bounds.x;
          break;
        default:
          newWidth = bounds.width;
      }
      let newHeight: number;
      switch (anchorY) {
        case -1:
          newHeight = bounds.height - (event.pageY - bounds.y);
          break;
        case 1:
          newHeight = event.pageY - bounds.y;
          break;
        default:
          newHeight = bounds.height;
      }
      setDraggingOutlinePositionAndDimension(newX, newY, newWidth, newHeight);
    },
  );

  return (
    <DragContext.Provider
      value={{
        anyDragging,
        setAnyDragging,
        setDraggingOutlinePositionAndDimension,
        setDraggingOutlineVisible,
        getDraggingOutlinePositionAndDimension,
        draggingHandler,
        resizeHandler,
      }}
    >
      {children}
    </DragContext.Provider>
  );
};

export const useDragContext = () => {
  const contextValue = React.useContext(DragContext);
  if (contextValue === null) {
    throw new Error("useDragContext must be used within a DragContextProvider");
  }
  return contextValue;
};

export const useAnyDragging = () => {
  const { anyDragging, setAnyDragging } = useDragContext();
  return { anyDragging, setAnyDragging };
};
