import React, { useRef } from "react";
import type { FederatedEventHandler, Graphics } from "pixi.js";
import { bottomRightBorderColor, font2, font2Dimension, topLeftBorderColor } from "~/utils/constants";
import { colorNameMap, getBitmapTextStyles, useFocused } from "@poscon/shared-frontend";

const fontName = font2;

type EdstButtonProps = {
  x: number;
  y?: number;
  onmousedown?: FederatedEventHandler;
  disabled?: boolean;
  text: string;
  width?: number;
};
export function EdstButton({
  onmousedown,
  disabled,
  text,
  x,
  y = 0,
  width = text.length * font2Dimension.width + 10,
}: EdstButtonProps) {
  const ref = useRef<Graphics>(null);
  const focused = useFocused(ref);

  const tint = disabled ? 0x707070 : 0xadadad;

  return (
    <container x={x} y={y} eventMode={disabled ? "none" : "passive"}>
      <graphics
        ref={ref}
        draw={(graphics) => {
          graphics.clear();

          graphics
            .rect(0, 0, width, font2Dimension.height + 4)
            .fill(0)
            .stroke({ width: 2, color: topLeftBorderColor });
          graphics
            .moveTo(width, 0)
            .lineTo(width, font2Dimension.height + 4)
            .lineTo(0, font2Dimension.height + 4)
            .stroke({ width: 2, color: bottomRightBorderColor });
          if (focused) {
            graphics
              .rect(-1, -1, width + 3, font2Dimension.height + 7)
              .stroke({ width: 1, color: colorNameMap.white });
          }
        }}
        eventMode="static"
        onMouseDown={onmousedown}
      />
      <bitmapText
        text={text.toUpperCase()}
        x={Math.floor((width - text.length * font2Dimension.width) / 2)}
        y={3}
        eventMode="none"
        style={{
          ...getBitmapTextStyles(fontName),
          fill: tint,
        }}
      />
    </container>
  );
}
