import { FlightplanId } from "@poscon/shared-types";
import { ComponentType } from "react";
import { useRootSelector } from "~/redux/hooks";
import { flightplanSelector } from "~/redux/slices/aircraftSlice";
import { Asel } from "~/types/asel";
import { EdstAselMenu } from "~/types/edstView";
import { PlanOptions } from "./views/PlanOptions";
import { AltitudeMenu, EdstAircraftMenuProps, HeadingMenu, HoldMenu, RouteMenu, SpeedMenu, XResMenu } from "./menus/AircraftMenu";
import { PoMenu } from "./prompts/PoMenu";
import { cpdlcMiniMOView } from "@poscon/shared-frontend";

const flightplanMenuMap: Record<EdstAselMenu, ComponentType<EdstAircraftMenuProps> | null> = {
  ALTITUDE_MENU: AltitudeMenu,
  CANCEL_HOLD_MENU: null,
  CHANGE_DEST_MENU: null,
  HEADING_MENU: HeadingMenu,
  HOLD_MENU: HoldMenu,
  [cpdlcMiniMOView]: null,
  PLAN_OPTIONS: PlanOptions,
  PO_MENU: PoMenu,
  PREV_ROUTE_MENU: null,
  FREETEXT_MENU: null,
  ROUTE_MENU: RouteMenu,
  SPEED_MENU: SpeedMenu,
  X_RES_MENU: XResMenu,
} as const;

type AselComponentProps = {
  fpId: FlightplanId;
  asel: Asel;
}

export const AselComponent = ({ fpId, asel }: AselComponentProps) => {
  const fp = useRootSelector((state) => flightplanSelector(state, fpId));

  const Component = asel?.menu ? flightplanMenuMap[asel.menu] : null;

  return fp && Component ? <Component key={fpId} fp={fp} asel={asel} /> : null;
}
